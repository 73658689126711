import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import Login from "./authentication/login";
import AcceptInvite from "./authentication/accept-invite";
import ForgotPassword from "./authentication/forgot-password";

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Routes>
        <Route path={"/login"} element={<Login />} />
        <Route path={"/forgot-password"} element={<ForgotPassword />} />
        <Route path={`/invite/:encodedLink`} element={<AcceptInvite />} />
        <Route path={``} element={<Navigate to={"/auth/login"} replace />} />
      </Routes>
    </Suspense>
  );
};

export default AppViews;
