import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  Menu,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Table,
  Upload,
} from "antd";
import Flex from "../../../components/shared-components/Flex";
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  InfoCircleOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useFirestore, useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { formatNameToURL } from "../../../functions/formatters";
import EllipsisDropdown from "../../../components/shared-components/EllipsisDropdown";
import { FIRESTORE_RECIPES_TABLE } from "../../../constants/FirestoreConstant";
import i18n from "../../../translations/i18n";
import ImgCrop from "antd-img-crop";
import firebase from "../../../configs/FirebaseConfig";
import styled from "styled-components";
import moment from "moment";

const { Option } = Select;

const ADD = "ADD";
const EDIT = "EDIT";
const imagesPath = "recipes";

const Recipes = () => {
  const { t } = useTranslation();
  const firestore = useFirestore();
  useFirestoreConnect([{ collection: FIRESTORE_RECIPES_TABLE }]);
  const recipes = useSelector((state) => state.firestoreReducer.ordered.recipes);
  const products = useSelector((state) => state.firestoreReducer.ordered.products);
  const email = useSelector((state) => state.firebaseReducer.auth.email);
  const [addRecipeForm] = Form.useForm();
  const [editRecipeForm] = Form.useForm();
  const [addRecipeModalVisible, setAddRecipeModalVisible] = useState(false);
  const [editRecipeModalVisible, setEditRecipeModalVisible] = useState(false);
  const [recipeToEdit, setRecipeToEdit] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [uploadedImageEn, setUploadedImageEn] = useState(null);
  const [selectedWidth, setSelectedWidth] = useState(null);
  const [isActive, setIsActive] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editImage, setEditImage] = useState(false);

  const openEditModal = (recipe) => {
    setRecipeToEdit(recipe);
    setEditImage(false);
    editRecipeForm.setFieldsValue(recipe);
    setEditRecipeModalVisible(true);
    setIsActive(recipe.active);
  };

  const deleteRecipe = (recipe) => {
    const storage = firebase.storage();
    const imageRef = storage.refFromURL(recipe.image);
    imageRef
      .delete()
      .then(() => {
        firestore
          .collection(FIRESTORE_RECIPES_TABLE)
          .doc(recipe.id)
          .delete()
          .then((r) => {
            message.success(t("recipe_removed"));
          });
      })
      .catch((err) => console.log(err));
  };

  const closeAddModal = () => {
    setAddRecipeModalVisible(false);
    setSelectedWidth(null);
    setUploadedImage(null);
    setUploadedImageEn(null);
    setLoading(false);
    addRecipeForm.resetFields();
  };

  const closeEditModal = () => {
    setEditRecipeModalVisible(false);
    setRecipeToEdit(null);
    setLoading(false);
    setUploadedImage(null);
    setUploadedImageEn(null);
    editRecipeForm.resetFields();
  };

  const handleUpdateRecipe = () => {
    editRecipeForm.validateFields().then((values) => {
      setLoading(true);
      values.products = !!values.products ? values.products : null;
      values.videoUrl = !!values.videoUrl ? values.videoUrl : null;
      delete values.image;
      let object = setRecipeLoggingProperties(values, EDIT);

      const updateRecipeInFirestore = (object) => {
        firestore
          .collection(FIRESTORE_RECIPES_TABLE)
          .doc(recipeToEdit.id)
          .update(object)
          .then(() => {
            closeEditModal();
            message.success(t("notifications.recipe_saved"), 2);
          })
          .catch((err) => {
            message.error(err.message);
            setLoading(false);
          });
      };

      if (uploadedImage) {
        const storage = firebase.storage();
        const oldImageRef = storage.refFromURL(recipeToEdit.image);
        oldImageRef
          .delete()
          .then(() => {
            const imageName = uploadedImage.uid + "-" + uploadedImage.name;
            const imageRef = firebase.storage().ref(imagesPath + "/" + imageName);
            return imageRef.put(uploadedImage.originFileObj);
          })
          .then((snapshot) => snapshot.ref.getDownloadURL())
          .then((url) => {
            object.image = url;
            updateRecipeInFirestore(object);
          })
          .catch((error) => {
            message.error(error.message);
            setLoading(false);
          });
      } else {
        updateRecipeInFirestore(object);
      }
    });
  };

  const setRecipeLoggingProperties = (recipe, mode) => {
    if (mode === ADD) return { ...recipe, createdAt: moment().unix(), createdBy: email };
    else if (mode === EDIT) return { ...recipe, updatedAt: moment().unix(), updatedBy: email };
  };

  const handleAddRecipe = () => {
    addRecipeForm.validateFields().then((values) => {
      setLoading(true);
      values.imageAlt = !!values.imageAlt ? values.imageAlt : null;
      values.products = !!values.products ? values.products : null;
      values.videoUrl = !!values.videoUrl ? values.videoUrl : null;
      delete values.image;
      let object = setRecipeLoggingProperties(values, ADD);
      const imageName = uploadedImage.uid + "-" + uploadedImage.name;
      const images = [{ name: imageName, file: uploadedImage.originFileObj }];

      Promise.all(
        // Array of "Promises"
        images.map((item) => putStorageItem(item)),
      )
        .then((urls) => {
          urls.forEach((item) => {
            object.image = item.url;
          });
          firestore
            .collection(FIRESTORE_RECIPES_TABLE)
            .add(object)
            .then((response) => closeAddModal())
            .catch((err) => {
              message.error(err);
              setLoading(false);
            });
        })
        .catch((error) => {
          console.log(error.message);
          setLoading(true);
        });
    });
  };

  const putStorageItem = (item) => {
    return new Promise((resolve, reject) => {
      const imageRef = firebase.storage().ref(imagesPath + "/" + item.name);
      imageRef
        .put(item.file)
        .then((snapshot) => {
          snapshot.ref.getDownloadURL().then((url) => {
            resolve({ lang: item.lang, url: url });
          });
        })
        .catch((error) => {
          reject(item, error.message);
        });
    });
  };

  const onChangeImage = (file, lang) => {
    const status = file.status;
    if (lang === "nl") {
      if (status === "done") setUploadedImage(file);
      if (status === "removed") setUploadedImage(null);
    } else {
      if (status === "done") setUploadedImageEn(file);
      if (status === "removed") setUploadedImageEn(null);
    }
  };

  /**
   * When file is uploaded, check for file type and image size
   * @param {*} file The uploaded file
   * @returns Boolean that tells Upload component whether to add image or not
   */
  const beforeUpload = (file) => {
    const isAcceptedImage = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/webp";
    if (!isAcceptedImage) {
      message.error(i18n.t("notifications.only_png_jpg"));
      return Upload.LIST_IGNORE;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error(i18n.t("notifications.image_max_size"));
      return Upload.LIST_IGNORE;
    }
    return isAcceptedImage && isLt2M;
  };

  /**
   * Image uploader expects to do a request, so we mock one
   */
  const dummyRequest = ({ file, onSuccess }) => {
    onSuccess("ok");
  };

  const formatRecipeUrl = (productName) => {
    const formattedName = formatNameToURL(productName);
    addRecipeForm.setFieldsValue({ url: formattedName });
  };

  return (
    <Card>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          marginBottom: 8,
        }}
      >
        <div>
          <Button onClick={() => setAddRecipeModalVisible(true)} type="primary" icon={<PlusCircleOutlined />} block>
            {t("add_recipe")}
          </Button>
        </div>
      </div>
      <div className="table-responsive">
        <Table
          columns={[
            {
              title: t("title"),
              dataIndex: "title",
            },
            {
              title: t("url"),
              dataIndex: "url",
              render: (url) => <span>{!!url ? "/" + url : "-"}</span>,
            },
            {
              title: t("tag"),
              dataIndex: "tag",
            },
            {
              title: t("image"),
              dataIndex: "image",
              render: (image) => <img src={image} alt={""} style={{ maxWidth: 100, maxHeight: 80 }} />,
            },
            {
              title: "",
              dataIndex: "actions",
              render: (_, row) => (
                <div className="text-right">
                  <EllipsisDropdown
                    menu={
                      <Menu>
                        <Menu.Item>
                          <Flex alignItems="center">
                            <EyeOutlined />
                            <a
                              href={"https://hetvleescentrum.nl/recepten/" + row.url}
                              target={"_blank"}
                              style={{ marginLeft: 4, color: "inherit" }}
                            >
                              {t("view")}
                            </a>
                          </Flex>
                        </Menu.Item>
                        <Menu.Item onClick={() => openEditModal(row)}>
                          <Flex alignItems="center">
                            <EditOutlined />
                            <span style={{ marginLeft: 4 }}>{t("edit")}</span>
                          </Flex>
                        </Menu.Item>
                        <Menu.Item>
                          <Flex alignItems="center">
                            <DeleteOutlined />
                            <Popconfirm
                              placement="bottom"
                              title={t("delete_recipe")}
                              onConfirm={() => deleteRecipe(row)}
                              okText={t("yes")}
                              cancelText={t("no")}
                            >
                              <span style={{ marginLeft: 4 }}>{t("delete")}</span>
                            </Popconfirm>
                          </Flex>
                        </Menu.Item>
                      </Menu>
                    }
                  />
                </div>
              ),
            },
          ]}
          dataSource={recipes}
          rowKey="id"
        />
      </div>
      <Modal
        title={t("add_recipe")}
        visible={addRecipeModalVisible}
        onCancel={() => closeAddModal()}
        onOk={handleAddRecipe}
        okText={t("save")}
        cancelText={t("close")}
        width={1200}
        confirmLoading={loading}
      >
        <Form layout="vertical" form={addRecipeForm} name="advanced_search" className="ant-advanced-search-form">
          <Row gutter={16}>
            <Col xs={24} lg={12}>
              <Form.Item
                name={"image"}
                label={t("image")}
                rules={[
                  () => ({
                    validator(rule, value) {
                      return !!uploadedImage ? Promise.resolve() : Promise.reject(t("form.upload_image"));
                    },
                  }),
                ]}
              >
                {!uploadedImage ? (
                  <ImgCrop
                    modalWidth={1000}
                    minZoom={0}
                    modalTitle={t("crop_image")}
                    modalOk={t("add")}
                    modalCancel={t("close")}
                    aspect={16 / 9}
                    cropperProps={{
                      restrictPosition: false,
                    }}
                  >
                    <Upload
                      beforeUpload={beforeUpload}
                      onChange={(e) => onChangeImage(e.file, "nl")}
                      customRequest={dummyRequest}
                      maxCount={1}
                      multiple={false}
                      showUploadList={false}
                    >
                      <Button icon={<UploadOutlined />}>{t("upload")}</Button>
                    </Upload>
                  </ImgCrop>
                ) : (
                  <ImageFileWrapper>
                    <p>{uploadedImage?.name}</p>
                    <DeleteOutlined onClick={() => setUploadedImage(null)} />
                  </ImageFileWrapper>
                )}
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item name={"imageAlt"} label={t("alt")}>
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} lg={14}>
              <Form.Item
                name="title"
                label={t("title")}
                rules={[
                  {
                    required: true,
                    message: t("form.enter_value"),
                  },
                ]}
              >
                <Input placeholder={t("title")} onChange={(e) => formatRecipeUrl(e.target.value)} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={10}>
              <Form.Item
                name={"url"}
                label={t("url")}
                tooltip={{ title: t("form.enter_value"), icon: <InfoCircleOutlined /> }}
                rules={[
                  {
                    required: true,
                    message: t("form.enter_value"),
                  },
                ]}
              >
                <Input addonBefore={process.env.REACT_APP_BASEURL + "/recepten/"} />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name="description"
                label={t("description")}
                rules={[
                  {
                    required: true,
                    message: t("form.enter_value"),
                  },
                ]}
              >
                <Input.TextArea placeholder={t("description")} rows={3} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={8}>
              <Form.Item
                name="tag"
                label={t("tag")}
                rules={[
                  {
                    required: true,
                    message: t("form.enter_value"),
                  },
                ]}
              >
                <Input placeholder={t("tag")} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={8}>
              <Form.Item
                name="minutes"
                label={t("minutes")}
                rules={[
                  {
                    required: true,
                    message: t("form.enter_value"),
                  },
                ]}
              >
                <InputNumber min={1} placeholder={t("minutes")} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={8}>
              <Form.Item
                name="servings"
                label={t("servings")}
                rules={[
                  {
                    required: true,
                    message: t("form.enter_value"),
                  },
                ]}
              >
                <InputNumber min={1} placeholder={t("servings")} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.List
                name="ingredients"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (getFieldValue("ingredients") && getFieldValue("ingredients").length) {
                        return Promise.resolve();
                      }
                      return message.error(t("form.enter_recipe_ingredients"));
                    },
                  }),
                ]}
              >
                {(fields, { add, remove }) => {
                  return (
                    <div className={"mt-2"}>
                      <h4 style={{ marginBottom: "1rem" }}>{t("ingredients")}</h4>
                      {fields.map((field, index) => (
                        <Row key={field.key} gutter={10}>
                          <Col sm={24} md={5}>
                            <Form.Item
                              {...field}
                              label={t("how_much")}
                              className="w-100"
                              name={[field.name, "amount"]}
                              fieldKey={[field.fieldKey, "amount"]}
                              rules={[
                                {
                                  required: true,
                                  message: t("form.enter_value"),
                                },
                              ]}
                            >
                              <InputNumber min={0.25} placeholder={t("how_much")} />
                            </Form.Item>
                          </Col>
                          <Col sm={24} md={6}>
                            <Form.Item
                              {...field}
                              label={t("unit")}
                              className="w-100"
                              name={[field.name, "unit"]}
                              fieldKey={[field.fieldKey, "unit"]}
                            >
                              <Select placeholder={t("unit")}>
                                <Option value={null}>-</Option>
                                <Option value={"g"}>{t("gram_short")}</Option>
                                <Option value={"kg"}>{t("kg_short")}</Option>
                                <Option value={"ml"}>{t("ml_short")}</Option>
                                <Option value={"l"}>{t("l_short")}</Option>
                                <Option value={"tbsp"}>{t("tbsp_short")}</Option>
                                <Option value={"tsp"}>{t("tsp_short")}</Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col sm={24} md={11}>
                            <Form.Item
                              {...field}
                              label={t("product")}
                              name={[field.name, "product"]}
                              fieldKey={[field.fieldKey, "product"]}
                              className="w-100"
                              rules={[
                                {
                                  required: true,
                                  message: t("form.enter_product"),
                                },
                              ]}
                            >
                              <Input placeholder={t("product")} />
                            </Form.Item>
                          </Col>
                          <Col sm={24} md={2} style={{ alignSelf: "center" }}>
                            <MinusCircleOutlined
                              onClick={() => {
                                remove(field.name);
                              }}
                            />
                          </Col>
                          <Col span={24}>
                            <hr className="mt-2" />
                          </Col>
                        </Row>
                      ))}
                      <Form.Item>
                        <Button type="dashed" onClick={() => add({ unit: null })} className="w-100">
                          <PlusOutlined /> {t("form.add_field")}
                        </Button>
                      </Form.Item>
                    </div>
                  );
                }}
              </Form.List>
            </Col>
            <Col xs={24} lg={12}>
              <Form.List
                name="steps"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (getFieldValue("steps") && getFieldValue("steps").length) {
                        return Promise.resolve();
                      }
                      return message.error(t("form.enter_recipe_steps"));
                    },
                  }),
                ]}
              >
                {(fields, { add, remove }) => {
                  return (
                    <div className={"mt-2"}>
                      <h4 style={{ marginBottom: "1rem" }}>{t("steps")}</h4>
                      {fields.map((field, index) => (
                        <Row key={field.key} gutter={10}>
                          <Col sm={24}>
                            <Form.Item
                              {...field}
                              label={t("description_preparation_step")}
                              className="w-100"
                              rules={[
                                {
                                  required: true,
                                  message: t("form.enter_product"),
                                },
                              ]}
                            >
                              <Input placeholder={t("product")} />
                            </Form.Item>
                          </Col>
                          <Col sm={24} md={2} style={{ alignSelf: "center" }}>
                            <MinusCircleOutlined
                              onClick={() => {
                                remove(field.name);
                              }}
                            />
                          </Col>
                          <Col span={24}>
                            <hr className="mt-2" />
                          </Col>
                        </Row>
                      ))}
                      <Form.Item>
                        <Button type="dashed" onClick={() => add()} className="w-100">
                          <PlusOutlined /> {t("form.add_field")}
                        </Button>
                      </Form.Item>
                    </div>
                  );
                }}
              </Form.List>
            </Col>
            <Col xs={24}>
              <Form.Item name="products" label={t("products_in_recipe")}>
                <Select mode="multiple" placeholder={t("products")} optionFilterProp="children">
                  {products.map((product, index) => {
                    return (
                      <Option value={product.id} key={index}>
                        {product.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item name="videoUrl" label={t("link_to_video")}>
                <Input placeholder={t("link_to_video")} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      {editRecipeModalVisible && (
        <Modal
          title={t("edit_recipe")}
          visible={editRecipeModalVisible}
          onCancel={() => closeEditModal()}
          onOk={handleUpdateRecipe}
          okText={t("save")}
          cancelText={t("close")}
          width={1000}
          confirmLoading={loading}
        >
          <Form layout="vertical" form={editRecipeForm} name="advanced_search" className="ant-advanced-search-form">
            <Row gutter={16}>
              <Col xs={24} lg={12}>
                <Form.Item
                  name={"image"}
                  label={t("image")}
                  rules={[
                    () => ({
                      validator(rule, value) {
                        return !!uploadedImage || (!!recipeToEdit?.image && !editImage)
                          ? Promise.resolve()
                          : Promise.reject(t("form.upload_image"));
                      },
                    }),
                  ]}
                >
                  {!uploadedImage && (!recipeToEdit?.image || editImage) ? (
                    <ImgCrop
                      modalWidth={1000}
                      minZoom={0}
                      modalTitle={t("crop_image")}
                      modalOk={t("add")}
                      modalCancel={t("close")}
                      aspect={16 / 9}
                      cropperProps={{
                        restrictPosition: false,
                      }}
                    >
                      <Upload
                        beforeUpload={beforeUpload}
                        onChange={(e) => onChangeImage(e.file, "nl")}
                        customRequest={dummyRequest}
                        maxCount={1}
                        multiple={false}
                        showUploadList={false}
                      >
                        <Button icon={<UploadOutlined />}>{t("upload")}</Button>
                      </Upload>
                    </ImgCrop>
                  ) : (
                    <ImageFileWrapper>
                      {(!!recipeToEdit?.image || uploadedImage) && (
                        <img
                          src={uploadedImage ? uploadedImage : recipeToEdit?.image}
                          alt={""}
                          style={{ maxWidth: 100, maxHeight: 80 }}
                        />
                      )}
                      <p>{uploadedImage?.name}</p>
                      <DeleteOutlined onClick={() => setEditImage(true)} />
                    </ImageFileWrapper>
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item name={"imageAlt"} label={t("alt")}>
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={14}>
                <Form.Item
                  name="title"
                  label={t("title")}
                  rules={[
                    {
                      required: true,
                      message: t("form.enter_value"),
                    },
                  ]}
                >
                  <Input placeholder={t("title")} onChange={(e) => formatRecipeUrl(e.target.value)} />
                </Form.Item>
              </Col>
              <Col lg={10}>
                <Form.Item
                  name={"url"}
                  label={t("url")}
                  tooltip={{ title: t("form.enter_value"), icon: <InfoCircleOutlined /> }}
                  rules={[
                    {
                      required: true,
                      message: t("form.enter_value"),
                    },
                  ]}
                >
                  <Input addonBefore={process.env.REACT_APP_BASEURL + "/recepten/"} />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  name="description"
                  label={t("description")}
                  rules={[
                    {
                      required: true,
                      message: t("form.enter_value"),
                    },
                  ]}
                >
                  <Input.TextArea placeholder={t("description")} rows={3} />
                </Form.Item>
              </Col>
              <Col lg={8}>
                <Form.Item
                  name="tag"
                  label={t("tag")}
                  rules={[
                    {
                      required: true,
                      message: t("form.enter_value"),
                    },
                  ]}
                >
                  <Input placeholder={t("tag")} />
                </Form.Item>
              </Col>
              <Col lg={8}>
                <Form.Item
                  name="minutes"
                  label={t("minutes")}
                  rules={[
                    {
                      required: true,
                      message: t("form.enter_value"),
                    },
                  ]}
                >
                  <InputNumber min={1} placeholder={t("minutes")} style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col lg={8}>
                <Form.Item
                  name="servings"
                  label={t("servings")}
                  rules={[
                    {
                      required: true,
                      message: t("form.enter_value"),
                    },
                  ]}
                >
                  <InputNumber min={1} placeholder={t("servings")} style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.List
                  name="ingredients"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (getFieldValue("ingredients") && getFieldValue("ingredients").length) {
                          return Promise.resolve();
                        }
                        return message.error(t("form.enter_recipe_ingredients"));
                      },
                    }),
                  ]}
                >
                  {(fields, { add, remove }) => {
                    return (
                      <div className={"mt-2"}>
                        <h4 style={{ marginBottom: "1rem" }}>{t("ingredients")}</h4>
                        {fields.map((field, index) => (
                          <Row key={field.key} gutter={10}>
                            <Col sm={24} md={5}>
                              <Form.Item
                                {...field}
                                label={t("how_much")}
                                className="w-100"
                                name={[field.name, "amount"]}
                                fieldKey={[field.fieldKey, "amount"]}
                                rules={[
                                  {
                                    required: true,
                                    message: t("form.enter_value"),
                                  },
                                ]}
                              >
                                <InputNumber min={0.25} placeholder={t("how_much")} />
                              </Form.Item>
                            </Col>
                            <Col sm={24} md={6}>
                              <Form.Item
                                {...field}
                                label={t("unit")}
                                className="w-100"
                                name={[field.name, "unit"]}
                                fieldKey={[field.fieldKey, "unit"]}
                              >
                                <Select placeholder={t("unit")}>
                                  <Option value={null}>-</Option>
                                  <Option value={"g"}>{t("gram_short")}</Option>
                                  <Option value={"kg"}>{t("kg_short")}</Option>
                                  <Option value={"ml"}>{t("ml_short")}</Option>
                                  <Option value={"l"}>{t("l_short")}</Option>
                                  <Option value={"tbsp"}>{t("tbsp_short")}</Option>
                                  <Option value={"tsp"}>{t("tsp_short")}</Option>
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col sm={24} md={11}>
                              <Form.Item
                                {...field}
                                label={t("product")}
                                name={[field.name, "product"]}
                                fieldKey={[field.fieldKey, "product"]}
                                className="w-100"
                                rules={[
                                  {
                                    required: true,
                                    message: t("form.enter_product"),
                                  },
                                ]}
                              >
                                <Input placeholder={t("product")} />
                              </Form.Item>
                            </Col>
                            <Col sm={24} md={2} style={{ alignSelf: "center" }}>
                              <MinusCircleOutlined
                                onClick={() => {
                                  remove(field.name);
                                }}
                              />
                            </Col>
                            <Col span={24}>
                              <hr className="mt-2" />
                            </Col>
                          </Row>
                        ))}
                        <Form.Item>
                          <Button type="dashed" onClick={() => add({ unit: null })} className="w-100">
                            <PlusOutlined /> {t("form.add_field")}
                          </Button>
                        </Form.Item>
                      </div>
                    );
                  }}
                </Form.List>
              </Col>
              <Col lg={12}>
                <Form.List
                  name="steps"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (getFieldValue("steps") && getFieldValue("steps").length) {
                          return Promise.resolve();
                        }
                        return message.error(t("form.enter_recipe_steps"));
                      },
                    }),
                  ]}
                >
                  {(fields, { add, remove }) => {
                    return (
                      <div className={"mt-2"}>
                        <h4 style={{ marginBottom: "1rem" }}>{t("steps")}</h4>
                        {fields.map((field, index) => (
                          <Row key={field.key} gutter={10}>
                            <Col sm={24} md={22}>
                              <Form.Item
                                {...field}
                                label={t("description_preparation_step")}
                                className="w-100"
                                rules={[
                                  {
                                    required: true,
                                    message: t("form.enter_product"),
                                  },
                                ]}
                              >
                                <Input placeholder={t("product")} />
                              </Form.Item>
                            </Col>
                            <Col sm={24} md={2} style={{ alignSelf: "center" }}>
                              <MinusCircleOutlined
                                onClick={() => {
                                  remove(field.name);
                                }}
                              />
                            </Col>
                            <Col span={24}>
                              <hr className="mt-2" />
                            </Col>
                          </Row>
                        ))}
                        <Form.Item>
                          <Button type="dashed" onClick={() => add()} className="w-100">
                            <PlusOutlined /> {t("form.add_field")}
                          </Button>
                        </Form.Item>
                      </div>
                    );
                  }}
                </Form.List>
              </Col>
              <Col lg={24}>
                <Form.Item name="products" label={t("products_in_recipe")}>
                  <Select mode="multiple" placeholder={t("products")} optionFilterProp="children">
                    {products.map((product, index) => {
                      return (
                        <Option value={product.id} key={index}>
                          {product.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item name="videoUrl" label={t("link_to_video")}>
                  <Input placeholder={t("link_to_video")} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      )}
    </Card>
  );
};

const ImageFileWrapper = styled.div`
  display: flex;
  transition: all 0.3s ease;
  padding: 0.2rem 0.3rem;
  align-items: center;

  p {
    margin-right: 1rem;
    margin-bottom: 0;
  }

  span {
    &:hover {
      cursor: pointer;
    }
  }

  &:hover {
    background-color: #f5f5f5;
  }
`;

export default Recipes;
