import React, { useState } from "react";
import { Button, Card, Col, Form, Input, InputNumber, message, TreeSelect } from "antd";
import { useTranslation } from "react-i18next";
import _, { intersection } from "lodash";
import { useFirestore } from "react-redux-firebase";
import { useSelector } from "react-redux";
import moment from "moment";

const AddDiscount = (props) => {
  const { t } = useTranslation();
  const firestore = useFirestore();
  const { products, categories } = props;
  const [submitLoading, setSubmitLoading] = useState(false);
  const [form] = Form.useForm();
  const email = useSelector((state) => state.firebaseReducer.auth.email);
  const promotions = useSelector((state) => state.firestoreReducer.ordered.promotions);

  /**
   * Submit promotion form
   */
  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        let enteredCategories = values.categories;
        let categoryAlreadyHasPromotion = _.find(promotions, function (o) {
          return o.categories.some((r) => enteredCategories.includes(r));
        });
        if (!!categoryAlreadyHasPromotion) {
          message.warning(t("notifications.promotion_category_overlap") + categoryAlreadyHasPromotion.name);
        } else {
          setSubmitLoading(true);
          let filteredProducts = products.filter(
            (product) => intersection(values.categories, product.categories).length > 0,
          );
          storePromotionToFirestore(values.discount, filteredProducts);
        }
      })
      .catch((err) => {
        setSubmitLoading(false);
        console.log(err);
      });
  };

  /**
   * Store promotion to firestore before applying discount to associated products
   * @param {*} discount Discount percentage to apply to associated products
   * @param {*} filteredProducts The products to apply the discount to
   */
  const storePromotionToFirestore = (discount, filteredProducts) => {
    let promotion = form.getFieldsValue();
    firestore
      .collection("promotions")
      .add({
        ...promotion,
        createdAt: moment().unix(),
        createdBy: email,
        active: true,
      })
      .then(() => {
        applyDiscountToProducts(discount, filteredProducts);
      })
      .catch((err) => message.error(err.message));
  };

  /**
   * Apply discount to all products associated to created promotion
   * @param {*} discount Discount percentage
   * @param {*} products All products to apply the discount to
   */
  const applyDiscountToProducts = (discount, products) => {
    // Get a new write batch
    let batch = firestore.batch();

    products.forEach((item) => {
      let ref = firestore.collection("products").doc(item.id);
      batch.update(ref, { discount: discount });
    });

    // Commit the batch
    batch
      .commit()
      .then(() => {
        form.resetFields();
        setSubmitLoading(false);
        message.success(t("notifications.discount_added", 4));
      })
      .catch((err) => console.log(err));
  };

  return (
    <Col xs={24} sm={24} md={6}>
      <Form layout="vertical" form={form} name="advanced_search" className="ant-advanced-search-form">
        <Card title={t("create_promotion")}>
          <Form.Item
            name="name"
            label={t("promotion_name")}
            rules={[
              {
                required: true,
                message: t("form.enter_promotion_name"),
              },
            ]}
          >
            <Input placeholder={t("promotion_name")} />
          </Form.Item>
          <Form.Item
            name="discount"
            label={t("discount")}
            rules={[
              {
                required: true,
                message: t("form.enter_discount_category"),
              },
            ]}
          >
            <InputNumber
              className="w-100"
              min={0}
              max={90}
              formatter={(value) => `${value}%`}
              parser={(value) => value.replace("%", "")}
            />
          </Form.Item>
          <Form.Item
            name="categories"
            label={t("categories")}
            rules={[
              {
                required: true,
                message: t("form.enter_category"),
              },
            ]}
          >
            <TreeSelect
              showSearch
              style={{ width: "100%", minWidth: 175 }}
              placeholder={t("category")}
              allowClear
              multiple
              treeDefaultExpandAll
              treeData={categories.map((category) => {
                return {
                  key: category.categoryId,
                  title: category.name,
                  value: category.categoryId,
                  children:
                    !!category.subcategories &&
                    category.subcategories.map((subcategory) => {
                      return {
                        title: subcategory.name,
                        value: subcategory.subcategoryId,
                        key: subcategory.subcategoryId,
                      };
                    }),
                };
              })}
            />
          </Form.Item>
          <Button type="primary" onClick={() => onSubmit()} htmlType="submit" loading={submitLoading}>
            {t("save")}
          </Button>
        </Card>
      </Form>
    </Col>
  );
};

export default AddDiscount;
