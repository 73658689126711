import React from "react";
import { Button, Card, Col, Form, Input, Row, Select } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import i18n from "../../../../translations/i18n";
import _ from "lodash";
import {
  VARIATION_PIECES,
  VARIATION_WEIGHT,
} from "constants/VariationConstant";

const { Option } = Select;

const ItemsField = ({ products }) => {
  const { t } = useTranslation();

  return (
    <Card title={t("items_in_product")}>
      <Form.List name="productItems">
        {(fields, { add, remove }) => {
          return (
            <div className="m-3">
              {fields.map((field, index) => (
                <Row key={field.key} gutter={10}>
                  <Col sm={24} md={9}>
                    <Form.Item
                      {...field}
                      label={i18n.t("product")}
                      className="w-100"
                      name={[field.name, "productId"]}
                      fieldKey={[field.fieldKey, "productId"]}
                      rules={[
                        {
                          required: true,
                          message: i18n.t("form.choose_product"),
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        key={index}
                        optionFilterProp="children"
                        filterOption={true}
                      >
                        {products.map((product) => (
                          <Option key={product.id} value={product.id}>
                            {product.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col sm={24} md={9}>
                    <Form.Item
                      {...field}
                      label={i18n.t("variations.value")}
                      name={[field.name, "value"]}
                      fieldKey={[field.fieldKey, "value"]}
                      className="w-100"
                      rules={[
                        {
                          required: true,
                          message: i18n.t("form.choose_pieces_amount"),
                        },
                      ]}
                    >
                      <Input type="number" className="w-100" min={1} />
                    </Form.Item>
                  </Col>
                  <Col sm={24} md={4}>
                    <Form.Item
                      {...field}
                      label={i18n.t("variations.type")}
                      name={[field.name, "type"]}
                      fieldKey={[field.fieldKey, "type"]}
                      className="w-100"
                    >
                      <Select
                        showSearch
                        key={index}
                        optionFilterProp="children"
                        filterOption={true}
                      >
                        <Option value={VARIATION_PIECES}>{t("pieces")}</Option>
                        <Option value={VARIATION_WEIGHT}>{t("weight")}</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col sm={24} md={2} style={{ alignSelf: "center" }}>
                    <MinusCircleOutlined
                      onClick={() => {
                        remove(field.name);
                      }}
                    />
                  </Col>
                  <Col span={24}>
                    <hr className="mt-2" />
                  </Col>
                </Row>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => {
                    add({ value: 1, type: VARIATION_PIECES });
                  }}
                  className="w-100"
                >
                  <PlusOutlined /> {t("form.add_field")}
                </Button>
              </Form.Item>
            </div>
          );
        }}
      </Form.List>
    </Card>
  );
};

export default ItemsField;
