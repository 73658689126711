import React from "react";
import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { LOGO_SRC } from "constants/CompanyConstants";

const SendLabel = React.forwardRef((props, ref) => {
  const data = props.data;
  return (
    <div ref={ref}>
      <Document>
        {data.map((order, index) => {
          return (
            <Page size="A6">
              <View key={index} style={styles.container}>
                <Text style={styles.pageNumber}>{index}</Text>
                <View style={styles.contentContainer}>
                  <View style={styles.logoContainer}>
                    <View>
                      <img src={LOGO_SRC} style={styles.logo} alt="logo" />
                    </View>
                  </View>
                  <Text style={styles.text}>#{order.orderId}</Text>
                  <Text style={styles.textWithMargin}>{order.name}</Text>
                  <Text style={styles.text}>{order.street_and_number}</Text>
                  <Text style={styles.text}>{order.zipcode_and_city}</Text>
                  <Text style={styles.text}>{order.phone}</Text>
                  <Text style={styles.text}>{order.type}</Text>
                  <Text style={styles.textWithMargin}>{order.deliveryType}</Text>
                  {!!order.orderNotes && <Text style={styles.text}>"{order.orderNotes}"</Text>}
                  <Text style={styles.textWithMargin}>{order.deliveryDate}</Text>
                  <Text style={styles.textWithMargin}>{order.dropPermission ? "Neerzettoestemming" : ""}</Text>
                </View>
              </View>
            </Page>
          );
        })}
      </Document>
    </div>
  );
});

const TEXT_SIZE = 20;
const TEXT_COLOR = "#3a3a3a";

const styles = StyleSheet.create({
  container: {
    display: "block",
    height: "100vh",
    flex: 1,
    padding: 30,
  },
  contentContainer: {
    display: "grid",
    justifyContent: "center",
    alignItems: "center",
  },
  logoContainer: {
    display: "block",
    marginBottom: 10,
  },
  logo: {
    display: "block",
    maxWidth: 150,
    maxHeight: 250,
    aspectRatio: "contain",
  },
  text: {
    fontSize: TEXT_SIZE,
    color: TEXT_COLOR,
  },
  textWithMargin: {
    fontSize: TEXT_SIZE,
    color: TEXT_COLOR,
    marginTop: 12,
  },
  pageNumber: {
    position: "absolute",
    left: 30,
    fontSize: 15,
    color: "#9e9e9e",
    display: "block",
  },
});

export default SendLabel;
