import { Row } from "antd";
import React from "react";
import AddDiscount from "./AddDiscount";
import DiscountList from "./DiscountList";

const DiscountCodes = () => {
  return (
    <Row gutter={16}>
      <AddDiscount />
      <DiscountList />
    </Row>
  );
};

export default DiscountCodes;
