/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Badge, Card, Col, Empty, Menu, message, Popconfirm, Table } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";
import _, { intersection } from "lodash";
import EllipsisDropdown from "components/shared-components/EllipsisDropdown";
import utils from "utils";
import { FIRESTORE_PRODUCTS_TABLE, FIRESTORE_PROMOTIONS_TABLE } from "constants/FirestoreConstant";
import { findCategoryById } from "../../../../functions/categories";

const DiscountList = (props) => {
  const firestore = useFirestore();
  const promotions = useSelector((state) => state.firestoreReducer.ordered.promotions);
  const email = useSelector((state) => state.firebaseReducer.auth.email);
  const [promotionToEdit, setPromotionToEdit] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [active, setActive] = useState(null);
  const { t } = useTranslation();
  const { products, categories } = props;

  const dropdownMenu = (row) => (
    <Menu>
      <Menu.Item>
        <Popconfirm
          placement="bottom"
          title={t("delete_promotion")}
          onConfirm={() => deletePromotion(row)}
          okText={t("yes")}
          cancelText={t("no")}
        >
          <a onClick={(e) => e.preventDefault}>{t("delete")}</a>
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );

  const tableColumns = [
    {
      title: t("promotion_name"),
      dataIndex: "name",
    },
    {
      title: t("discount"),
      dataIndex: "discount",
      render: (data) => <span>{data}%</span>,
    },
    {
      title: t("categories"),
      dataIndex: "categories",
      render: (category) =>
        !!category &&
        category.map((cat, i) => (
          <span key={i}>
            {findCategoryById(categories, cat)?.name}
            {i !== category.length - 1 && ","}{" "}
          </span>
        )),
    },
    {
      title: "Status",
      dataIndex: "active",
      render: (active) => <div style={{ display: "flex", gap: 4 }}>{getStockStatus(active)}</div>,
      sorter: (a, b) => utils.antdTableSorter(a, b, "stock"),
    },
    {
      title: "",
      dataIndex: "actions",
      render: (_, elm) => (
        <div className="text-right">
          <EllipsisDropdown menu={dropdownMenu(elm)} />
        </div>
      ),
    },
  ];

  const getStockStatus = (active) => {
    if (active)
      return (
        <>
          <Badge status="success" />
          <span>{t("active")}</span>
        </>
      );
    else
      return (
        <>
          <Badge status="warning" />
          <span>{t("inactive")}</span>
        </>
      );
  };

  /**
   * Remove promotion from firestore
   * @param promotion Promotion to delete
   */
  const deletePromotion = (promotion) => {
    firestore
      .collection(FIRESTORE_PROMOTIONS_TABLE)
      .doc(promotion.id)
      .delete()
      .then(() => {
        // After deleting promotion, remove discount from associated products
        removeDiscountFromProducts(promotion);
        message.success(t("notifications.promotion_deleted", 4));
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  /**
   * Remove discount from all products associated with promotion
   * @param {*} promotion Promotion that has been deleted/deactivated
   */
  const removeDiscountFromProducts = (promotion) => {
    let selectedCategories = promotion.categories;
    let filteredProducts = getFilteredProducts(selectedCategories);

    let activePromotions = _.filter(promotions, function (item) {
      return item.active && item.id !== promotion.id;
    });

    if (!!filteredProducts) {
      // Get a new write batch
      let batch = firestore.batch();

      // Remove discount from all products that belong to (categories in) promotion
      filteredProducts.forEach((item) => {
        let ref = firestore.collection(FIRESTORE_PRODUCTS_TABLE).doc(item.id);
        let newDiscount = 0;

        // Check if other promotion is active with discount. If so, apply that discount %
        let categoryHasPromotion = _.find(activePromotions, function (o) {
          return intersection(o.categories, item.categories).length > 0;
        });
        if (!!categoryHasPromotion) newDiscount = categoryHasPromotion.discount;

        batch.update(ref, { discount: newDiscount });
      });

      // Commit the batch
      batch
        .commit()
        .then(() => {
          setPromotionToEdit(null);
          setSubmitLoading(false);
        })
        .catch((err) => console.log(err));
    }
  };

  /**
   * Get products by category
   * @param {*} categories Categories of which we want the products
   * @returns Filtered products in array
   */
  const getFilteredProducts = (categories) => {
    return products.filter((product) => intersection(categories, product.categories));
  };

  return (
    <Col sm={24} md={18}>
      <Card>
        <div className="table-responsive">
          <Table
            columns={tableColumns}
            dataSource={promotions}
            rowKey="id"
            locale={{
              emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("table_no_promotions")} />,
            }}
          />
        </div>
      </Card>
    </Col>
  );
};

export default DiscountList;
