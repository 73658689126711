import { message } from "antd";
import { DATE_FORMAT_DD_MM_YYYY, DATE_FORMAT_YYYY_MM_DD } from "constants/DateConstant";
import { showInfiniteNotification } from "functions/notifications";
import moment from "moment";
import { Trans } from "react-i18next";
import i18n from "translations/i18n";
import { FIRESTORE_ORDERS_TABLE } from "../../constants/FirestoreConstant";
import { ORDER_STATUS_SHIPPED } from "../../constants/OrderConstant";
import { DELIVERY_DURATION } from "../../constants/DeliveryConstants";

/**
 * Send all selected orders to OptimoRoute application through an API call
 * @param orders The orders that should be sent to the OptimoRoute
 * @param firestore
 */
export const createDeliveryOrder = (orders, firestore) => {
  return new Promise((resolve, reject) => {
    let counter = 0;
    let errorCounter = 0;
    let totalCounter = 0;
    let errorMessages = "";
    orders.forEach((order) => {
      sendToOptimoRoute(order)
        .then(() => {
          totalCounter++;
          counter++;
          // Update firestore order status
          firestore.collection(FIRESTORE_ORDERS_TABLE).doc(order.id).update({
            orderStatus: ORDER_STATUS_SHIPPED,
          });
          if (totalCounter === orders.length) {
            showOptimoResults(counter, errorCounter, errorMessages);
            resolve();
          }
        })
        .catch((errorMessage) => {
          totalCounter++;
          errorCounter++;
          errorMessages += errorMessage;
          if (totalCounter === orders.length) {
            showOptimoResults(counter, errorCounter, errorMessages);
            reject();
          }
        });
    });
  });
};

/**
 * Send order to OptimoRoute, add errorMessage to promise result to show to user if anything goes wrong
 * @param order
 */
const sendToOptimoRoute = (order) => {
  return new Promise((resolve, reject) => {
    let delivery = order.customer.address.delivery;
    const { street, houseNumber, addition, zipCode, city } = delivery;
    let deliveryAddress = street + " " + houseNumber + (!!addition ? ` ${addition}` : "") + ", " + zipCode + " " + city;
    fetch(process.env.REACT_APP_OPTIMO_ROUTE_CREATE_ORDER + process.env.REACT_APP_OPTIMO_ROUTE_API_KEY, {
      method: "POST",
      body: JSON.stringify({
        date: moment(order.delivery.date, DATE_FORMAT_DD_MM_YYYY).format(DATE_FORMAT_YYYY_MM_DD),
        location: {
          address: deliveryAddress,
        },
        orderNo: order.orderId,
        notes: !!order.orderNotes ? order.orderNotes : "",
        operation: "CREATE",
        type: "D",
        email: order.customer.email,
        duration: DELIVERY_DURATION,
        notificationPreference: "email",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
          let errorMessage = order.orderId + ": " + data.message + "\n\n";
          reject(errorMessage);
        } else resolve();
      })
      .catch((err) => reject(err));
  });
};

/**
 * Show amount of orders that succesfully got sent to OptimoRoute and show error messages to user, if any.
 * @param counter Counter of succeeded orders sent to OptimoRoute
 * @param errorCounter Counter of orders that couldn't get sent to OptimoRoute
 * @param errorMessages All error messages in one string
 */
const showOptimoResults = (counter, errorCounter, errorMessages) => {
  if (counter > 0) {
    message.success(
      <Trans
        i18nKey="notifications.optimoroute_uploaded"
        values={{
          count: counter,
        }}
      />,
    );
  }
  if (errorCounter > 0) {
    showInfiniteNotification(i18n.t("notifications.fix_following_errors"), errorMessages);
  }
};
