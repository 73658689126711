import React from "react";
import PropTypes from "prop-types";
import { Avatar, Badge } from "antd";
import { StarFilled } from "@ant-design/icons";

import { Link } from "react-router-dom";

const renderAvatar = (props, isProductOfTheWeek) => {
  return (
    <Badge count={isProductOfTheWeek ? <StarFilled style={{ color: "#FFD21F", fontSize: 21 }} /> : 0}>
      <Avatar {...props} className={`ant-avatar-${props.type}`}>
        {props.text}
      </Avatar>
    </Badge>
  );
};

export const AvatarStatus = (props) => {
  const { name, suffix, subTitle, id, type, src, icon, size, shape, gap, text, linkTo, isProductOfTheWeek } = props;
  return (
    <div className="avatar-status d-flex align-items-center">
      {renderAvatar({ icon, src, type, size, shape, gap, text }, isProductOfTheWeek)}
      <div className="ml-2">
        <div>
          {linkTo ? (
            <Link to={linkTo} className="avatar-status-name clickable" style={{ marginLeft: 6 }}>
              {name}
            </Link>
          ) : (
            <div className="avatar-status-name" style={{ marginLeft: 6 }}>
              {name}
            </div>
          )}
          <span>{suffix}</span>
        </div>
        <div className="text-muted avatar-status-subtitle" style={{ marginLeft: 6 }}>
          {subTitle}
        </div>
      </div>
    </div>
  );
};

AvatarStatus.propTypes = {
  name: PropTypes.string,
  src: PropTypes.string,
  type: PropTypes.string,
  onNameClick: PropTypes.func,
};

export default AvatarStatus;
