/**
 * Validate if product URL already exists or not
 * @param url
 * @param id
 * @param products
 * @returns {boolean}
 */
export const validProductUrl = (url, id, products) => {
  return !products.some((product) => product.url === url && id !== product.id);
};
