export const COMPANY_CONSTANTS = {
  COMPANY_NAME: "Het Vleescentrum",
  STREET_AND_NUMBER: "De Hoek 91A",
  ZIP_AND_CITY: "1601MR, Enkhuizen",
  COUNTRY: "Nederland",
  KVK: "72794380",
  VAT: "NL859239056B01",
  WEBSITE_URL: "hetvleescentrum.nl",
  WEBSITE_NAME: "Het Vleescentrum",
};
export const LOGO_SRC = "/img/logo.webp";
export const REVIEW_QR_CODE = "/img/qr-code/review.png";
