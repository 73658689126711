import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Empty,
  Form,
  Input,
  InputNumber,
  Menu,
  message,
  notification,
  Popconfirm,
  Table,
  TreeSelect,
} from "antd";
import { DeleteOutlined, EditOutlined, PlusCircleOutlined, SearchOutlined, StarOutlined } from "@ant-design/icons";
import AvatarStatus from "components/shared-components/AvatarStatus";
import EllipsisDropdown from "components/shared-components/EllipsisDropdown";
import Flex from "components/shared-components/Flex";
import { useNavigate } from "react-router-dom";
import utils from "utils";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { isLoaded, useFirebase, useFirestore } from "react-redux-firebase";
import { deleteAssociatedFilesFromStorage } from "functions/firebase/storage";
import urls from "urls";
import { formatPrice } from "../../../../functions/formatters";
import Loading from "../../../../components/shared-components/Loading";
import _, { cloneDeep, concat } from "lodash";
import {
  deleteProductLinkedVariants,
  updateProduct,
  updateProductPrice,
} from "../../../../functions/firestore/product";
import { findCategoryById, getProductsByCategory } from "../../../../functions/categories";
import { FIRESTORE_PRODUCTS_TABLE } from "../../../../constants/FirestoreConstant";

const ProductList = () => {
  const navigate = useNavigate();
  const firestore = useFirestore();
  const firebase = useFirebase();
  const { t } = useTranslation();
  const products = useSelector((state) => state.firestoreReducer.ordered.products);
  const categories = useSelector((state) => state.firestoreReducer.ordered.categories);
  const [list, setList] = useState(products);
  const [filteredList, setFilteredList] = useState(products);
  const [clickedCategory, setClickedCategory] = useState(localStorage.getItem("categoryFilter") || "all");
  const [tablePage, setTablePage] = useState(parseInt(localStorage.getItem("prdListPagination")) || 1);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    if (!!products) setIsLoading(false);
    handleShowCategory(!!clickedCategory ? clickedCategory : "all", tablePage);
  }, [products]);

  const dropdownMenu = (row) => (
    <Menu>
      <Menu.Item onClick={() => viewDetails(row)}>
        <Flex alignItems="center">
          <EditOutlined />
          <span style={{ marginLeft: 4 }}>{t("edit")}</span>
        </Flex>
      </Menu.Item>
      {!row.productOfTheWeek && (
        <Menu.Item onClick={() => makeProductOfTheWeek(row)}>
          <Flex alignItems="center">
            <StarOutlined />
            <span style={{ marginLeft: 4 }}>{t("product_of_the_week")}</span>
          </Flex>
        </Menu.Item>
      )}
      <Menu.Item>
        <Popconfirm
          placement="bottom"
          title={t("confirm_delete_product", { name: row.name })}
          onConfirm={() => beforeDeleteRow(row)}
          okText={t("yes")}
          cancelText={t("no")}
        >
          <a onClick={(e) => e.preventDefault}>
            <DeleteOutlined />
            {t("delete")}
          </a>
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );

  const makeProductOfTheWeek = (row) => {
    const oldProductOfTheWeek = _.find(products, { productOfTheWeek: true });

    if (oldProductOfTheWeek) {
      firestore
        .collection(FIRESTORE_PRODUCTS_TABLE)
        .doc(oldProductOfTheWeek.id)
        .update({ productOfTheWeek: false })
        .then(() => {
          firestore
            .collection(FIRESTORE_PRODUCTS_TABLE)
            .doc(row.id)
            .update({ productOfTheWeek: true })
            .then(() => {
              message.success(t("new_product_of_the_week", { product: row.name }), 4);
            })
            .catch((err) => message.error(err.message));
        })
        .catch((err) => message.error(err.message));
    } else {
      // No product of the week yet
      firestore
        .collection(FIRESTORE_PRODUCTS_TABLE)
        .doc(row.id)
        .update({ productOfTheWeek: true })
        .then(() => {
          message.success(t("new_product_of_the_week", { product: row.name }), 4);
        })
        .catch((err) => message.error(err.message));
    }
  };

  const addProduct = () => {
    navigate(urls.addProduct);
  };

  const viewDetails = (row) => {
    navigate(urls.editProduct + row.id);
  };

  const beforeDeleteRow = (row) => {
    let associatedProducts = _.filter(products, {
      productItems: [{ productId: row.id }],
    });
    if (associatedProducts.length > 0) {
      let itemsString = "";
      _.forEach(associatedProducts, (item) => (itemsString += item.name + "\n"));
      notification.open({
        message: t("notifications.linked_products_title"),
        description: t("notifications.linked_products_description") + itemsString,
        duration: 0,
        key: row.id,
        btn: (
          <Button
            type="primary"
            onClick={() => {
              deleteRow(row);
              notification.close(row.id);
            }}
          >
            {t("proceed")}
          </Button>
        ),
      });
    } else deleteRow(row);
  };

  const deleteRow = (row) => {
    const objKey = "id";
    let data = list;
    let images = row.image;
    deleteAssociatedFilesFromStorage(firebase, images);
    firestore
      .collection("products")
      .doc(row.id)
      .delete()
      .then(() => {
        data = utils.deleteArrayRow(data, objKey, row.id);
        setList(data);
        message.success(t("notifications.product_deleted", 4));
        deleteAssociatedProductItems(row.id);
      })
      .catch((error) => {
        message.error(error.message, 4);
      });
    deleteProductLinkedVariants(firestore, products, row.id);
  };

  const deleteAssociatedProductItems = (id) => {
    let associatedProducts = _.filter(products, {
      productItems: [{ productId: id }],
    });
    if (associatedProducts.length > 0) {
      _.forEach(associatedProducts, (product) => {
        let productItems = product.productItems;
        let remainingItems = _.filter(productItems, (item) => {
          return item.productId !== id;
        });

        if (remainingItems.length === 0) remainingItems = null;
        firestore.collection("products").doc(product.id).update({ productItems: remainingItems });
      });
    }
  };

  const tableColumns = [
    {
      title: t("product"),
      dataIndex: "name",
      render: (_, record) => (
        <div className="d-flex">
          <AvatarStatus
            size={60}
            type="square"
            src={!!record.image ? record.image[0] : null}
            name={record.name}
            linkTo={urls.editProduct + record.id}
            isProductOfTheWeek={record.productOfTheWeek}
          />
        </div>
      ),
      sorter: (a, b) => utils.antdTableSorter(a, b, "name"),
    },
    {
      title: t("categories"),
      dataIndex: "categories",
      render: (cat) =>
        cat.map((category, i) => {
          return (
            <span key={i}>
              {findCategoryById(categories, category)?.name}
              {i !== cat.length - 1 && ","}{" "}
            </span>
          );
        }),
      sorter: (a, b) => utils.antdTableSorter(a, b, "category"),
    },
    {
      title: t("price"),
      dataIndex: "price",
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: "price",
        title: t("price"),
        handleSavePrice,
      }),
      render: (price) => <div>€ {formatPrice(price)}</div>,
      sorter: (a, b) => utils.antdTableSorter(a, b, "price"),
    },
    {
      title: t("tax_rate"),
      dataIndex: "taxRate",
      render: (taxRate) => <span>{!!taxRate ? taxRate : 0}%</span>,
      sorter: (a, b) => utils.antdTableSorter(a, b, "taxRate"),
    },
    {
      title: t("discount"),
      dataIndex: "discount",
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: "discount",
        title: t("discount"),
        handleSaveDiscount,
      }),
      render: (discount) => <span>{!!discount ? discount : 0}%</span>,
      sorter: (a, b) => utils.antdTableSorter(a, b, "discount"),
    },
    {
      title: "",
      dataIndex: "actions",
      render: (_, elm) => (
        <div className="text-right">
          <EllipsisDropdown menu={dropdownMenu(elm)} />
        </div>
      ),
    },
  ];

  const onSearch = (e) => {
    const value = e.currentTarget.value;
    setSearchText(value);
    const data = utils.wildCardSearchProductName(list, value);
    setFilteredList(data);
  };

  const handleShowCategory = (id) => {
    localStorage.setItem("categoryFilter", id);
    localStorage.setItem("prdListPagination", 1);
    setClickedCategory(id);
    setSearchText("");
    if (id !== "all") {
      const category = findCategoryById(categories, id);
      const filteredProducts = getProductsByCategory(products, category);
      setList(filteredProducts);
      setFilteredList(filteredProducts);
    } else {
      setList(products);
      setFilteredList(products);
    }
  };

  const onChangeTablePage = (value) => {
    localStorage.setItem("prdListPagination", value);
    setTablePage(value);
  };

  const handleSavePrice = (row, initialPrice) => {
    const newPrice = row.price;
    const variationWithInitialPrice = !!row.variations ? row.variations.find((v) => v.price === initialPrice) : null;
    const newVariations = !!row.variations ? cloneDeep(row.variations) : null;
    if (!!variationWithInitialPrice) {
      const variationIndex = row.variations.indexOf(variationWithInitialPrice);
      newVariations[variationIndex].price = newPrice;
    }
    if (newPrice !== initialPrice && newPrice > 0) {
      const id = row.id;
      updateProductPrice(id, newPrice);
      if (!!newVariations) updateProduct(id, { variations: newVariations });
    }
  };

  const handleSaveDiscount = (row, initialDiscount) => {
    if (row.discount !== initialDiscount) {
      const id = row.id;
      updateProduct(id, { discount: row.discount });
    }
  };

  const components = {
    body: {
      row: PriceRow,
      cell: (cellProps) => {
        // Bepaal op basis van de dataIndex welke cell component gebruikt moet worden
        if (cellProps.dataIndex === "price") {
          return <PriceCell {...cellProps} />;
        } else if (cellProps.dataIndex === "discount") {
          return <DiscountCell {...cellProps} />;
        }
        // Voor andere cellen, return standaard td element
        return <td {...cellProps}>{cellProps.children}</td>;
      },
    },
  };

  return (
    <Card>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 8,
          gap: 8,
          flexWrap: "wrap",
        }}
      >
        <div style={{ display: "flex", gap: 8, flexWrap: "wrap" }}>
          <Input
            value={searchText}
            placeholder={t("search")}
            prefix={<SearchOutlined />}
            onChange={(e) => onSearch(e)}
          />
          <TreeSelect
            onChange={handleShowCategory}
            showSearch
            style={{ width: "100%", minWidth: 175 }}
            placeholder={t("category")}
            allowClear
            treeDefaultExpandAll
            value={clickedCategory || "all"}
            treeData={concat([{ slug: "all", key: "all", value: "all", categoryId: "all" }], categories).map(
              (category) => {
                return {
                  key: category.categoryId,
                  title: category.key === "all" ? t("all_categories") : category.name,
                  value: category.categoryId,
                  children:
                    !!category.subcategories &&
                    category.subcategories.map((subcategory) => {
                      return {
                        title: subcategory.name,
                        value: subcategory.subcategoryId,
                        key: subcategory.subcategoryId,
                      };
                    }),
                };
              },
            )}
          />
        </div>
        <div style={{ display: "flex", gap: 8 }}>
          <Button onClick={addProduct} type="primary" icon={<PlusCircleOutlined />} block className="w-auto">
            {t("add_product")}
          </Button>
        </div>
      </div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="table-responsive">
          <Table
            components={components}
            columns={tableColumns}
            dataSource={filteredList}
            rowKey="id"
            pagination={{
              current: !!tablePage ? tablePage : 1,
              onChange: onChangeTablePage,
            }}
            locale={{
              emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("table_no_products")} />,
            }}
            loading={!isLoaded(products)}
          />
        </div>
      )}
    </Card>
  );
};

const EditableContext = React.createContext(null);

const PriceRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const PriceCell = ({ title, editable, children, dataIndex, record, handleSavePrice, ...restProps }) => {
  const { t } = useTranslation();
  const [editing, setEditing] = useState(false);
  const [initialPrice, setInitialPrice] = useState(null);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
      if (!initialPrice) setInitialPrice(record.price);
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSavePrice({ ...record, ...values }, initialPrice);
      setInitialPrice(values.price);
    } catch (err) {
      console.log("Save failed:", err);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: t("form.enter_price"),
          },
        ]}
      >
        <InputNumber
          ref={inputRef}
          onPressEnter={save}
          onBlur={save}
          className="w-100"
          formatter={(value) => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        />
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const DiscountCell = ({ title, editable, children, dataIndex, record, handleSaveDiscount, ...restProps }) => {
  const { t } = useTranslation();
  const [editing, setEditing] = useState(false);
  const [initialDiscount, setInitialDiscount] = useState(null);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
      if (!initialDiscount) setInitialDiscount(record.discount);
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      console.log(values);
      handleSaveDiscount({ ...record, ...values }, initialDiscount);
      setInitialDiscount(values.discount);
    } catch (err) {
      console.log("Save failed:", err);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: t("form.enter_discount"),
          },
        ]}
      >
        <InputNumber
          ref={inputRef}
          onPressEnter={save}
          onBlur={save}
          className="w-100"
          formatter={(value) => `${value}%`}
          min={0}
          max={99}
        />
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

export default ProductList;
