import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import AppLayout from "layouts/app-layout";
import AuthLayout from "layouts/auth-layout";
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from "configs/AppConfig";
import { isEmpty, isLoaded } from "react-redux-firebase";
import { useSelector } from "react-redux";

// A wrapper that routes user to the dashboard when he/she lands on
// the login page while already authenticated
const CheckAlreadyAuthenticated = ({ children, auth }) => {
  return isEmpty(auth) || !auth.emailVerified ? children : <Navigate to={APP_PREFIX_PATH} replace />;
};

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated or if auth is not
// yet loaded
const PrivateRoute = ({ children, auth }) => {
  return isLoaded(auth) && !isEmpty(auth) ? children : <Navigate to={AUTH_PREFIX_PATH} replace />;
};

export const Views = () => {
  const location = useLocation();
  const auth = useSelector((state) => state.firebaseReducer.auth);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <>
            <Navigate to={APP_PREFIX_PATH} replace />
          </>
        }
      />
      <Route
        path={AUTH_PREFIX_PATH + "/*"}
        element={
          <>
            <CheckAlreadyAuthenticated auth={auth}>
              <AuthLayout />
            </CheckAlreadyAuthenticated>
          </>
        }
      />
      <Route
        path={APP_PREFIX_PATH + "/*"}
        element={
          <>
            <PrivateRoute auth={auth}>
              <AppLayout location={location} />
            </PrivateRoute>
          </>
        }
      />
    </Routes>
  );
};

export default Views;
