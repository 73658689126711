import React, { useEffect, useState } from "react";
import { Card, Input, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Flex from "components/shared-components/Flex";
import utils from "utils";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { find } from "lodash";

const Customers = () => {
  const webshopUsers = useSelector((state) => state.firestoreReducer.ordered.webshopUsers);
  const loyaltyUsers = useSelector((state) => state.firestoreReducer.ordered.loyaltyUsers);
  const [customerList, setCustomerList] = useState(null);
  const [filteredCustomerList, setFilteredCustomerList] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (!!webshopUsers && !!loyaltyUsers) {
      const mergedCustomerList = [];
      webshopUsers.map((user) => {
        const loyaltyUser = find(loyaltyUsers, (u) => u.id === user.id);
        if (!!loyaltyUser) {
          const mergedUser = {
            ...user,
            currentPoints: loyaltyUser.currentPoints,
            totalPointsEarned: loyaltyUser.totalPointsEarned,
            totalPointsSpent: loyaltyUser.totalPointsSpent,
          };
          mergedCustomerList.push(mergedUser);
        } else mergedCustomerList.push(user);
      });

      setCustomerList(mergedCustomerList);
      setFilteredCustomerList(mergedCustomerList);
    }
  }, [webshopUsers, loyaltyUsers]);

  const tableColumns = [
    {
      title: t("email"),
      dataIndex: "email",
      render: (data) => <span>{data}</span>,
      sorter: (a, b) => utils.antdTableSorter(a, b, "email"),
    },
    {
      title: t("customer.firstName"),
      dataIndex: "firstName",
      render: (data) => (data ? <span>{data}</span> : <span> - </span>),
    },
    {
      title: t("customer.lastName"),
      dataIndex: "lastName",
      render: (data) => (data ? <span>{data}</span> : <span> - </span>),
    },
    {
      title: t("phone"),
      dataIndex: "phoneNumber",
      render: (data) => (data ? <span>{data}</span> : <span> - </span>),
    },
    {
      title: t("customer.houseAddress"),
      dataIndex: "address",
      render: (data) =>
        data ? (
          <span>
            {data.delivery.street} {data.delivery.houseNumber} {data.delivery.addition}
          </span>
        ) : (
          <span> - </span>
        ),
    },
    {
      title: t("customer.zipcode"),
      dataIndex: "address",
      render: (data) => (data ? <span>{data.delivery.zipCode}</span> : <span> - </span>),
    },
    {
      title: t("customer.city"),
      dataIndex: "address",
      render: (data) => (data ? <span>{data.delivery.city}</span> : <span> - </span>),
    },
    {
      title: t("customer.loyalty_points_current"),
      dataIndex: "currentPoints",
      render: (data) => (data ? <span>{data}</span> : <span> - </span>),
    },
    {
      title: t("customer.loyalty_points_total"),
      dataIndex: "totalPointsEarned",
      render: (data) => (data ? <span>{data}</span> : <span> - </span>),
      sorter: (a, b) => utils.antdTableSorter(a, b, "totalPointsEarned"),
    },
    {
      title: t("customer.loyalty_points_spent"),
      dataIndex: "totalPointsSpent",
      render: (data) => (data ? <span>{data}</span> : <span> - </span>),
      sorter: (a, b) => utils.antdTableSorter(a, b, "totalPointsSpent"),
    },
  ];

  const onSearch = (e) => {
    const value = e.currentTarget.value;
    const data = utils.wildCardSearchOnCustomer(customerList, value);
    setFilteredCustomerList(data);
  };

  return (
    <Card>
      <Flex alignItems="center" justifyContent="between" mobileFlex={false}>
        <Flex className="mb-1" mobileFlex={false}>
          <div className="mr-md-3 mb-3">
            <Input placeholder={t("search")} prefix={<SearchOutlined />} onChange={(e) => onSearch(e)} />
          </div>
        </Flex>
      </Flex>
      <div className="table-responsive">
        <Table columns={tableColumns} dataSource={filteredCustomerList} rowKey="email" scroll={{ x: 1300 }} />
      </div>
    </Card>
  );
};

export default Customers;
