import _ from "lodash";
import i18n from "translations/i18n";
import { DELIVERY_OPTIONS } from "constants/DeliveryConstants";
import { VARIATION_PIECES, VARIATION_WEIGHT } from "constants/VariationConstant";
import moment from "moment";
import { order_invoice } from "../urls";
import { findCategoryById, findMainCategoryBySubId } from "./categories";

export const getPickListData = function (data, allProducts, categories) {
  let uniqueProducts = [];
  data.map((order) => {
    // Create array that will contain all product objects
    let products = [];
    order.products.map((product) => {
      let currentProduct = _.find(allProducts, ["id", product.productId]);

      let productList = !!currentProduct && currentProduct.productItems ? currentProduct.productItems : null;

      // If product contains a list of other products, we need those individual items in the picklist
      if (!!productList) {
        // For every item in the productList, push every individual product into the products array
        productList.map((item, index) => {
          let productById = _.find(allProducts, ["id", item.productId]);
          let category;
          if (productById.categories) {
            let foundCategory = findCategoryById(categories, productById.categories[0]);
            if (!foundCategory) {
              category = "Verwijderde categorie";
            } else category = foundCategory.name;
            if (!!foundCategory && foundCategory.subcategoryId) {
              let mainCategory = findMainCategoryBySubId(categories, foundCategory.subcategoryId);
              category = mainCategory.name;
            }
          } else {
            if (typeof productById.category === "object") {
              category = productById.category[0];
            } else {
              let foundCategory = findCategoryById(categories, productById.category);
              if (!foundCategory) {
                category = "Verwijderde categorie";
              } else category = foundCategory?.name;
              if (category?.subcategoryId) {
                let foundCategory = findMainCategoryBySubId(categories, category.subcategoryId);
                if (!foundCategory) category = "Verwijderde categorie";
                else category = foundCategory?.name;
              }
            }
          }
          if (!product.giftCard)
            products.push({
              key: productById.id + index,
              name: productById.name,
              type: null,
              quantity: item.type === VARIATION_PIECES ? product.quantity * item.value : product.quantity,
              weight: item.type === VARIATION_WEIGHT ? item.value : null,
              amountPerOrder: item.type === VARIATION_PIECES ? [product.quantity * item.value] : [product.quantity],
              category: category,
            });
        });
      } else {
        let category;
        if (product.categories) {
          let foundCategory = findCategoryById(categories, product.categories[0]);
          if (!foundCategory) {
            category = "Verwijderde categorie";
          } else category = foundCategory.name;
          if (!!foundCategory && foundCategory.subcategoryId && typeof foundCategory.subcategoryId === "string") {
            let mainCategory = findMainCategoryBySubId(categories, foundCategory.subcategoryId);
            if (!mainCategory) {
              console.log("foundCategory: ", foundCategory);
              console.log("mainCategory: ", mainCategory);
              console.log(categories);
            }
            category = mainCategory?.name || "Verwijderde categorie";
          }
        } else {
          if (typeof product.category === "object") {
            category = product.category[0];
          } else {
            let foundCategory = findCategoryById(categories, product.category);
            if (!foundCategory) category = "Verwijderde categorie";
            else {
              category = foundCategory.name;
              if (category.subcategoryId) {
                let foundCategory = findMainCategoryBySubId(categories, category.subcategoryId);
                console.log(foundCategory);
                category = foundCategory?.name || "Verwijderde categorie";
              }
            }
          }
        }
        // Product does not contain a productList, so just put the product into the products array;
        if (!product.giftCard)
          products.push({
            key: product.variantId,
            name: product.type ? product.name + " (" + product.type + ")" : product.name,
            type: product.type,
            quantity: product.quantity,
            weight: product.variation && product.variation.weight ? product.variation.weight : null,
            amountPerOrder: [product.quantity],
            category: category,
          });
      }
    });

    products.map((product) => {
      // For every product, check if it exists in the uniqueProducts array
      // Check for type, weight and name.
      // If it exists, increment the product based on quantity, if not add it to the array
      let uniqueProductIndex = _.findIndex(uniqueProducts, (o) => {
        if (product.type && product.weight)
          return o.type === product.type && o.weight === product.weight && o.name === product.name;
        else if (!product.type && product.weight) return o.weight === product.weight && o.name === product.name;
        else if (product.type && !product.weight) {
          return o.name === product.name;
        } else if (!product.type && !product.weight) return o.name === product.name;
      });
      if (uniqueProductIndex < 0) {
        uniqueProducts.push(product);
      } else {
        uniqueProducts[uniqueProductIndex].quantity += product.quantity;
        uniqueProducts[uniqueProductIndex].amountPerOrder.push(product.quantity);
      }
    });
  });

  return _.sortBy(uniqueProducts, (product) => product.category);
};

export const getSendListData = function (data) {
  let array = [];
  data.map((order) => {
    let customer = order.customer;
    let deliveryAddress = customer.address.delivery;
    let obj = {
      name: customer.firstName + " " + customer.lastName,
      street_and_number:
        deliveryAddress.street +
        " " +
        deliveryAddress.houseNumber +
        (!!deliveryAddress.addition ? deliveryAddress.addition : ""),
      zipcode_and_city: deliveryAddress.zipCode + " " + deliveryAddress.city,
      phone: customer.phoneNumber,
      deliveryType: order.delivery.type === DELIVERY_OPTIONS.SEND ? i18n.t("shipping") : i18n.t("pickup"),
      deliveryDate: order.delivery.date,
      orderId: order.orderId,
      orderNotes: order.orderNotes,
      dropPermission: order.delivery.dropPermission,
    };
    if (!order.containsOnlyGiftCards) array.push(obj);
  });
  return array;
};

/**
 * Get dates to disable for the datepicker
 * @param {*} current Current day in DatePicker
 * @param {*} deliveryType Delivery type selected by user
 * @returns
 */
export const getDisabledDatesForDatePicker = (current, deliveryType) => {
  let today = moment();
  let todayIndex = parseInt(moment(today).format("d"));
  let FRIDAY = 5;
  let SATURDAY = 6;
  let SUNDAY = 0;
  let MONDAY = 1;
  let excludeOffDays;

  if (deliveryType === DELIVERY_OPTIONS.SEND) {
    excludeOffDays =
      parseInt(moment(current).format("d")) === SATURDAY ||
      parseInt(moment(current).format("d")) === SUNDAY ||
      parseInt(moment(current).format("d")) === MONDAY;
  } else if (deliveryType === DELIVERY_OPTIONS.PICKUP) {
    excludeOffDays =
      parseInt(moment(current).format("d")) === SUNDAY || parseInt(moment(current).format("d")) === MONDAY;
  }

  if (todayIndex === FRIDAY) {
    if (deliveryType === DELIVERY_OPTIONS.SEND) {
      return moment(today).add(2, "days") >= current || excludeOffDays;
    } else if (deliveryType === DELIVERY_OPTIONS.PICKUP) {
      return moment(today).add(0, "days") >= current || excludeOffDays;
    }
  }

  if (todayIndex === SATURDAY) return moment(today).add(2, "days") >= current || excludeOffDays;
  else if (todayIndex === SUNDAY) return moment(today).add(1, "days") >= current || excludeOffDays;
  else return excludeOffDays;
};

/**
 * Generate invoice based on the order
 * @param order
 * @returns confirmation response
 */
export function generateInvoice(order) {
  return new Promise(function (resolve, reject) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(order),
    };
    fetch(order_invoice, requestOptions)
      .then((invoice) => resolve(invoice))
      .catch((error) => reject(error));
  });
}
