import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Customers from "./customers";
import CustomerFeedback from "./feedback";

const PARENT_URL = "/cms/customers";

const CustomersRoutes = () => {
  return (
    <Routes>
      <Route path={`/customers`} element={<Customers />} />
      <Route path={`/feedback`} element={<CustomerFeedback />} />
      <Route path={``} element={<Navigate to={`${PARENT_URL}/customers`} replace />} />
    </Routes>
  );
};

export default CustomersRoutes;
