import _ from "lodash";

/**
 * Takes an array of objects, filter by given attribute and get all unique values
 * @param {*} array Array of Objects that contains the array with values
 * @param {*} key The key of the attribute in the object that has to be filtered
 * @returns Array of unique values
 */
export const uniqueMergeArrays = (array, key) => {
  if (!!array) {
    let values = array.map((item) => item[key]).filter((item) => item !== undefined);
    return _.uniq(values.flat());
  } else return null;
};

/**
 * Merge array by child key element
 * @param array Array of Objects
 * @param key Child Object to get the values from
 * @param attribute to merge on
 * @returns Merged Array on attribute containing unique values
 */
export const mergeArrayByKeyAttribute = (array, key, attribute) => {
  if (!!array) {
    let values = array.map((item) => item[key]);
    return  _.uniqBy(values, attribute);
  }
}

/**
 * Count number of occurrences in a Array of objects
 * @param array Array of Objects
 * @param key Child Object to get the values from
 * @param childKey Key to count
 * @param value Unique value to filter on
 * @returns {*}
 */
export const countNumberOfOccurrences = (array, key, childKey, value) => {
  let values = array.map((item) => item[key]);
  return _.countBy(values, childKey)[value];
}