import "@mdxeditor/editor/style.css";
import {
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  CreateLink,
  headingsPlugin,
  imagePlugin,
  InsertImage,
  linkDialogPlugin,
  linkPlugin,
  listsPlugin,
  ListsToggle,
  markdownShortcutPlugin,
  MDXEditor,
  quotePlugin,
  Separator,
  toolbarPlugin,
} from "@mdxeditor/editor";
import styled from "styled-components";
import React, { useEffect, useRef } from "react";
import { beforeBlogPostImageUpload } from "../../functions/images";
import { uploadImageToStorage } from "../../functions/firebase/storage";

export const MarkdownEditor = ({ markdown, setMarkdown, savedContent = null }) => {
  const ref = useRef(null);

  useEffect(() => {
    if (!markdown) ref.current?.setMarkdown("");
  }, [markdown]);

  useEffect(() => {
    !!savedContent && ref.current?.setMarkdown(savedContent);
  }, [savedContent]);

  const imageUploadHandler = async (image) => {
    beforeBlogPostImageUpload(image);
    const imageToUpload = { file: image, name: image.name };
    const upload = await uploadImageToStorage(imageToUpload, "blogPosts");
    return upload.url;
  };

  return (
    <StyledEditor
      ref={ref}
      plugins={[
        toolbarPlugin({
          toolbarContents: () => (
            <>
              <BoldItalicUnderlineToggles />
              <Separator />
              <ListsToggle />
              <Separator />
              <BlockTypeSelect />
              <Separator />
              <InsertImage />
              <CreateLink />
            </>
          ),
        }),
        headingsPlugin(),
        listsPlugin(),
        linkPlugin(),
        linkDialogPlugin(),
        quotePlugin(),
        markdownShortcutPlugin(),
        imagePlugin({ imageUploadHandler, disableImageResize: true }),
      ]}
      markdown={markdown}
      onChange={setMarkdown}
    />
  );
};

const StyledEditor = styled(MDXEditor)`
  border: 1px solid #d9d9d9;
  border-radius: 8px;

  ul {
    list-style: circle;
    margin-left: 1rem;
  }

  li {
    font-weight: 300;
    font-size: 16px;

    @media (min-width: 1200px) {
      font-size: 18px;
    }
  }

  a {
    color: blue;
    text-decoration: underline;
  }

  img {
    width: 100%;
    max-width: 500px;
    height: auto;
  }
`;

export default MarkdownEditor;
