import { combineReducers } from "redux";
import Theme from "./Theme";
import Queries from "./Queries";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";

const reducers = combineReducers({
  firebaseReducer,
  firestoreReducer,
  theme: Theme,
  queries: Queries,
});

export default reducers;
