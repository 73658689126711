import React, { useState } from "react";
import { Button, Form, Input, message } from "antd";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useFirebase } from "react-redux-firebase";
import { useSelector } from "react-redux";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { dashboard } from "../../../urls";

export const LoginForm = (props) => {
  const firebase = useFirebase();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showForgetPassword, onForgetPasswordClick, extra } = props;
  const initialCredentials = { email: "", password: "" };
  const [loginEmail, setLoginEmail] = useState(initialCredentials.email);
  const [loginPassword, setLoginPassword] = useState(initialCredentials.password);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const cmsUsers = useSelector((state) => state.firestoreReducer.ordered.cmsUsers);

  const sendVerificationMail = () => {
    firebase
      .auth()
      .currentUser.sendEmailVerification()
      .then(() =>
        message.success({
          content: t("notifications.invite_link_sent"),
          duration: 8,
        }),
      )
      .catch((err) => message.warning(err, 8));
  };

  const onLogin = () => {
    setErrorMessage("");
    setIsLoading(true);
    let isCmsUser = _.findIndex(cmsUsers, ["email", loginEmail]) >= 0;
    if (isCmsUser) {
      firebase
        .login({ email: loginEmail, password: loginPassword })
        .then((user) => {
          if (!user.user.user.emailVerified) {
            message.warning({
              content: t("notifications.verify_email"),
              duration: 8,
              onClick: sendVerificationMail,
            });
            setIsLoading(false);
          } else navigate(dashboard);
        })
        .catch((err) => {
          setIsLoading(false);
          message.warning(err.message);
          setErrorMessage(err.code);
        });
    } else {
      setIsLoading(false);
      setErrorMessage(t("notifications.unauthorized_user"));
    }
  };

  return (
    <>
      <Form layout="vertical" name="login-form" onFinish={onLogin} className="mt-4" initialValues={initialCredentials}>
        <Form.Item
          name="email"
          label={t("email")}
          onChange={(e) => setLoginEmail(e.target.value)}
          rules={[
            {
              required: true,
              message: t("form.enter_email"),
            },
            {
              type: "email",
              message: t("form.enter_valid_email"),
            },
          ]}
        >
          <Input prefix={<MailOutlined className="text-primary" />} />
        </Form.Item>
        <Form.Item
          name="password"
          onChange={(e) => setLoginPassword(e.target.value)}
          label={
            <div className={`${showForgetPassword ? "d-flex justify-content-between w-100 align-items-center" : ""}`}>
              <span>{t("password")}</span>
              {showForgetPassword && (
                <span
                  onClick={() => onForgetPasswordClick}
                  className="cursor-pointer font-size-sm font-weight-normal text-muted"
                >
                  {t("forgot_password")}
                </span>
              )}
            </div>
          }
          rules={[
            {
              required: true,
              message: t("form.enter_password"),
            },
          ]}
        >
          <Input.Password prefix={<LockOutlined className="text-primary" />} />
        </Form.Item>
        <div className="w-100 text-center mb-2">
          <span className="text-danger">{errorMessage}</span>
        </div>
        <Form.Item>
          <Button type="primary" htmlType="submit" block loading={isLoading}>
            {t("login")}
          </Button>
        </Form.Item>
        {extra}
      </Form>
    </>
  );
};

LoginForm.propTypes = {
  showForgetPassword: PropTypes.bool,
  extra: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

LoginForm.defaultProps = {
  showForgetPassword: false,
};

export default LoginForm;
