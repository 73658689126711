import { applyMiddleware, createStore } from "redux";
import { thunk } from "redux-thunk";
import reducers from "../reducers";
import firestoreConfig from "configs/FirestoreConfig";
import firebase from "configs/FirebaseConfig";
import { createFirestoreInstance } from "redux-firestore";
import { load, save } from "redux-localstorage-simple";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";

const composeEnhancers = process.env.NODE_ENV === "development" ? composeWithDevTools : (f) => f;

const store = createStore(
  reducers,
  load(),
  composeEnhancers(applyMiddleware(thunk, save({ ignoreStates: ["queries"] }))),
);

export const rrfProps = {
  firebase,
  config: firestoreConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

export default store;
