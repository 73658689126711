import React, { useEffect, useState } from "react";
import { Button, Card, Form, Menu, message, Modal, Popconfirm, Select, Table } from "antd";
import { DeleteOutlined, EditOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import Flex from "components/shared-components/Flex";
import { useFirestore } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { FIRESTORE_FEATURED_PRODUCTS_TABLE } from "../../../../constants/FirestoreConstant";
import EllipsisDropdown from "../../../../components/shared-components/EllipsisDropdown";
import { getTypeById, getUnassignedFeaturedProductTypes } from "../../../../functions/featuredProducts";
import { FEATURED_PRODUCT_LIST_TYPES } from "../../../../constants/FeaturedProductsConstants";
import { getFeaturedListTypeName } from "../../../../functions/formatters";
import _ from "lodash";

const { Option } = Select;

const FeaturedProducts = () => {
  const { t } = useTranslation();
  const firestore = useFirestore();
  const featuredProducts = useSelector((state) => state.firestoreReducer.ordered.featuredProducts);
  const products = useSelector((state) => state.firestoreReducer.ordered.products);
  const [addListForm] = Form.useForm();
  const [editListForm] = Form.useForm();
  const [addListModalVisible, setAddListModalVisible] = useState(false);
  const [editListModalVisible, setEditListModalVisible] = useState(false);
  const [listToEdit, setListToEdit] = useState(null);
  const [typesForSelect, setTypesForSelect] = useState(
    getUnassignedFeaturedProductTypes(FEATURED_PRODUCT_LIST_TYPES, featuredProducts)
  );

  useEffect(() => {
    setTypesForSelect(getUnassignedFeaturedProductTypes(FEATURED_PRODUCT_LIST_TYPES, featuredProducts));
  }, [featuredProducts]);

  const addList = () => {
    setAddListModalVisible(true);
  };

  const openEditModal = (list) => {
    setListToEdit(list);
    let newTypesForSelect = _.clone(typesForSelect);
    let selectedProducts = [];
    let selectedType = getTypeById(list.type, FEATURED_PRODUCT_LIST_TYPES);
    newTypesForSelect.push(selectedType);
    setTypesForSelect(newTypesForSelect);
    if (!!list.products) {
      list.products.forEach((item) => {
        selectedProducts.push(item);
      });
    }
    editListForm.setFieldsValue({
      products: selectedProducts,
      type: list.type,
    });
    setEditListModalVisible(true);
  };

  const handleUpdateList = () => {
    let listObj = editListForm.getFieldsValue();
    firestore
      .collection(FIRESTORE_FEATURED_PRODUCTS_TABLE)
      .doc(listToEdit.id)
      .update(listObj)
      .then(() => {
        closeEditModal();
        message.success(t("list_saved"), 2);
      });
  };

  const deleteList = (list) => {
    firestore
      .collection(FIRESTORE_FEATURED_PRODUCTS_TABLE)
      .doc(list.id)
      .delete()
      .then((r) => {
        message.success(t("list_removed"));
      });
  };

  const closeAddModal = () => {
    setAddListModalVisible(false);
    addListForm.resetFields();
  };

  const closeEditModal = () => {
    setEditListModalVisible(false);
    setListToEdit(null);
    setTypesForSelect(getUnassignedFeaturedProductTypes(FEATURED_PRODUCT_LIST_TYPES, featuredProducts));
    editListForm.resetFields();
  };

  const handleAddList = () => {
    addListForm.validateFields().then((values) => {
      let object = {
        type: values.type,
        products: !!values.products ? values.products : null,
      };
      firestore
        .collection(FIRESTORE_FEATURED_PRODUCTS_TABLE)
        .add(object)
        .then((response) => {
          addListForm.resetFields();
          closeAddModal();
        })
        .catch((err) => message.error(err.message));
    });
  };

  const tableColumns = [
    {
      title: t("type"),
      dataIndex: "type",
      render: (type) => <span>{getFeaturedListTypeName(type)}</span>,
    },
    {
      title: t("products"),
      dataIndex: "products",
      render: (products) => <span>{!!products ? products.length : 0}</span>,
      sorter: {
        compare: (a, b) => a.products.length - b.products.length,
      },
    },
    {
      title: "",
      dataIndex: "actions",
      render: (_, row) => (
        <div className="text-right">
          <EllipsisDropdown
            menu={
              <Menu>
                <Menu.Item onClick={() => openEditModal(row)}>
                  <Flex alignItems="center">
                    <EditOutlined />
                    <span style={{ marginLeft: 4 }}>{t("edit")}</span>
                  </Flex>
                </Menu.Item>
                <Menu.Item>
                  <Flex alignItems="center">
                    <DeleteOutlined />
                    <Popconfirm
                      placement="bottom"
                      title={t("confirm_delete_list", {
                        name: getFeaturedListTypeName(row.type),
                      })}
                      onConfirm={() => deleteList(row)}
                      okText={t("yes")}
                      cancelText={t("no")}
                    >
                      <span style={{ marginLeft: 4 }}>{t("delete")}</span>
                    </Popconfirm>
                  </Flex>
                </Menu.Item>
              </Menu>
            }
          />
        </div>
      ),
    },
  ];

  return (
    <Card>
      <Flex alignItems="between" justifyContent="between" mobileFlex={false}>
        <Flex alignItems="end" className="mb-1" mobileFlex={false}>
          <div>
            <Button onClick={addList} type="primary" icon={<PlusCircleOutlined />} block>
              {t("add_list")}
            </Button>
          </div>
        </Flex>
      </Flex>
      <div className="table-responsive">
        <Table columns={tableColumns} dataSource={featuredProducts} rowKey="id" />
      </div>
      <Modal
        title={t("add_list")}
        visible={addListModalVisible}
        onCancel={() => closeAddModal()}
        onOk={handleAddList}
        okText={t("save")}
        cancelText={t("close")}
      >
        <Form layout="vertical" form={addListForm} name="advanced_search" className="ant-advanced-search-form">
          <Form.Item
            name="type"
            label={t("type")}
            rules={[
              {
                required: true,
                message: t("form.choose_type"),
              },
            ]}
          >
            <Select placeholder={t("type")}>
              {typesForSelect.map((type, index) => {
                return (
                  <Option value={type.id} key={index}>
                    {type.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item name="products" label={t("products")}>
            <Select
              mode="multiple"
              placeholder={t("products")}
              optionFilterProp="children"
            >
              {products.map((product, index) => {
                return (
                  <Option value={product.id} key={index}>
                    {product.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Form>
      </Modal>

      {editListModalVisible && (
        <Modal
          title={t("edit_list")}
          visible={editListModalVisible}
          onCancel={() => closeEditModal()}
          onOk={handleUpdateList}
          okText={t("save")}
          cancelText={t("close")}
        >
          <Form layout="vertical" form={editListForm} name="advanced_search" className="ant-advanced-search-form">
            <Form.Item
              name="type"
              label={t("type")}
              rules={[
                {
                  required: true,
                  message: t("form.choose_type"),
                },
              ]}
            >
              <Select placeholder={t("type")}>
                {typesForSelect.map((type, index) => {
                  return (
                    <Option value={type.id} key={index}>
                      {type.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item name="products" label={t("products")}>
              <Select mode="multiple" placeholder={t("products")} optionFilterProp="children">
                {products.map((product, index) => {
                  return (
                    <Option value={product.id} key={index}>
                      {product.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </Card>
  );
};

export default FeaturedProducts;
