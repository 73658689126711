import _ from "lodash";

export const getUnassignedFeaturedProductTypes = (allTypes, lists) => {
  let array = [];
  let usedTypes = [];

  if (!!lists) {
    lists.forEach((item) => {
      usedTypes.push(item.type);
    });
    allTypes.forEach((item) => {
      if (!usedTypes.includes(item.id)) array.push(item);
    });

    return array;
  } else return allTypes;
};

export const getTypeById = (type, allTypes) => {
  return _.find(allTypes, item => item.id === type)
}