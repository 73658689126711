import React, { useState } from "react";
import { Card, Row, Col, Form, Input, Button, message } from "antd";
import { MailOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import _ from "lodash";
import { useFirebase } from "react-redux-firebase";

const backgroundStyle = {
  backgroundImage: "url(/img/others/img-17.jpg)",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
};

const ForgotPassword = () => {
  const { t } = useTranslation();
  const firebase = useFirebase();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const cmsUsers = useSelector((state) => state.firestoreReducer.ordered.cmsUsers);

  const onSubmit = () => {
    setLoading(true);
    let isCmsUser = _.findIndex(cmsUsers, ["email", email]) >= 0;

    if (isCmsUser) {
      firebase
        .resetPassword(email)
        .then(() => {
          setLoading(false);
          setEmail("");
          message.success(t("notifications.password_reset_send"));
        })
        .catch((err) => {
          setLoading(false);
          message.error(err.message);
        });
    } else {
      setLoading(false);
      message.warning(t("notifications.unauthorized_user"));
    }
  };

  return (
    <div className="h-100" style={backgroundStyle}>
      <div className="container d-flex flex-column justify-content-center h-100">
        <Row justify="center">
          <Col xs={20} sm={20} md={20} lg={9}>
            <Card>
              <div className="my-2">
                <div className="text-center">
                  <img className="img-fluid w-75" src="/img/logo.webp" alt="" />
                  <h3 className="mt-3 font-weight-bold">{t("forgot_password")}</h3>
                  <p className="mb-4">{t("form.enter_email")}</p>
                </div>
                <Row justify="center">
                  <Col xs={24} sm={24} md={20} lg={20}>
                    <Form form={form} layout="vertical" name="forgot-password" onFinish={onSubmit}>
                      <Form.Item
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}
                        rules={[
                          {
                            required: true,
                            message: t("form.enter_email"),
                          },
                          {
                            type: "email",
                            message: t("form.enter_valid_email"),
                          },
                        ]}
                      >
                        <Input placeholder={t("email")} prefix={<MailOutlined className="text-primary" />} />
                      </Form.Item>
                      <Form.Item>
                        <Button loading={loading} type="primary" htmlType="submit" block>
                          {loading ? t("sending") : t("send")}
                        </Button>
                      </Form.Item>
                    </Form>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ForgotPassword;
