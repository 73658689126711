const base = process.env.REACT_APP_BASEURL;
const dashboardUrl = "/cms";
const authUrl = "/auth";
const mailService = process.env.REACT_APP_API_URL + "/api/email";

module.exports = {
  base: base,
  dashboard: dashboardUrl,
  auth: authUrl,
  login: authUrl + "/login",
  forgotPassword: authUrl + "/forgot-password",
  acceptInvite: authUrl + "/invite/",
  products: dashboardUrl + "/products",
  productList: dashboardUrl + "/products/product-list",
  addProduct: dashboardUrl + "/products/add-product",
  editProduct: dashboardUrl + "/products/edit-product/",
  featuredProducts: dashboardUrl + "/products/featured",
  categories: dashboardUrl + "/products/categories",
  discounts: dashboardUrl + "/discounts/",
  promotions: dashboardUrl + "/discounts/promotions",
  discountCodes: dashboardUrl + "/discounts/discount-codes",
  ordersList: dashboardUrl + "/orders",
  banners: dashboardUrl + "/banners",
  customerList: dashboardUrl + "/customers/customers",
  customerFeedback: dashboardUrl + "/customers/feedback",
  users: dashboardUrl + "/admin/users",
  reports: dashboardUrl + "/reports",
  recipes: dashboardUrl + "/recipes",
  blog: dashboardUrl + "/blog",
  order_invoice: mailService + "/order/invoice",
  sendInvite: mailService + "/invite/cms",
};
