import {
  ORDER_STATUS_COMPLETED,
  ORDER_STATUS_CREATED,
  ORDER_STATUS_PAID,
  ORDER_STATUS_RETURNED,
  ORDER_STATUS_SHIPPED,
} from "constants/OrderConstant";
import i18n from "translations/i18n";
import { LIST_TYPE_FEATURED, LIST_TYPE_PRIORITY } from "../constants/FeaturedProductsConstants";
import { BANNER_FULL_WIDTH, BANNER_HALF_WIDTH, BANNER_THIRD_WIDTH } from "../constants/BannerConstants";
import moment from "moment";

export const formatPrice = function (value) {
  if (!!value) {
    return value.toLocaleString("nl-NL", {
      minimumFractionDigits: value % 1 === 0 ? 0 : 2,
      maximumFractionDigits: 2,
    });
  } else return 0;
};

export const getPaymentStatus = (status) => {
  if (status === ORDER_STATUS_PAID) return i18n.t("order.status_paid");
  else if (status === ORDER_STATUS_SHIPPED) return i18n.t("order.status_shipped");
  else if (status === ORDER_STATUS_COMPLETED) return i18n.t("order.status_completed");
  else if (status === ORDER_STATUS_RETURNED) return i18n.t("order.status_returned");
  else if (status === ORDER_STATUS_CREATED) return i18n.t("order.status_created");
  else if (status === "all") return i18n.t("all");
};

export const formatNameToURL = (name) => {
  name = name.replace(/^\s+|\s+$/g, "");
  name = name.toLowerCase();

  // remove accents, swap ñ for n, etc
  let from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  let to = "aaaaeeeeiiiioooouuuunc------";
  for (let i = 0, l = from.length; i < l; i++) {
    name = name.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  // remove invalid chars
  // collapse whitespace and replace by -
  // collapse dashes
  name = name
    .replace(/[^a-z0-9 -]/g, "")
    .replace(/\s+/g, "-")
    .replace(/-+/g, "-");

  return name;
};

export const getFeaturedListTypeName = (type) => {
  if (type === LIST_TYPE_FEATURED) return i18n.t("featured");
  if (type === LIST_TYPE_PRIORITY) return i18n.t("priority");
};

export const formatBannerWidth = (width) => {
  if (width === BANNER_FULL_WIDTH.id) return i18n.t("full_width");
  if (width === BANNER_HALF_WIDTH.id) return i18n.t("half_width");
  if (width === BANNER_THIRD_WIDTH.id) return i18n.t("third_width");
};

export const formatOrderDate = (timestamp) => {
  return moment.unix(timestamp).format("DD/MM/YYYY");
};

export const formatInvoiceFileName = (invoice, orderId) => {
  return invoice + "-" + orderId + ".pdf";
};
