import { FIRESTORE_ORDERS_TABLE } from "../../constants/FirestoreConstant";
import firebase from "../../configs/FirebaseConfig";

const firestore = firebase.firestore();

export const getOrdersInPeriod = (start, end) => {
  return new Promise((resolve, reject) => {
    const orders = [];
    firestore
      .collection(FIRESTORE_ORDERS_TABLE)
      .where("createdAt", ">=", start)
      .where("createdAt", "<=", end)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          orders.push(doc.data());
        });
        resolve(orders);
      })
      .catch((err) => reject(err));
  });
};
