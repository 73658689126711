import React from "react";
import ProductForm from "../ProductForm";
import { useParams } from "react-router-dom";

const EditProduct = () => {
  const { id } = useParams();

  return <ProductForm mode="EDIT" id={id} />;
};

export default EditProduct;
