import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Promotions from "./promotions";
import DiscountCodes from "./discount-codes";

const PARENT_URL = "/cms/discounts";

const Discounts = () => {
  return (
    <Routes>
      <Route path={`/promotions`} element={<Promotions />} />
      <Route path={`/discount-codes`} element={<DiscountCodes />} />
      <Route path={``} element={<Navigate to={`${PARENT_URL}/promotions`} replace />} />
    </Routes>
  );
};

export default Discounts;
