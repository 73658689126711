import { Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import AddDiscount from "./AddDiscount";
import DiscountList from "./DiscountList";

const Promotions = () => {
  const products = useSelector((state) => state.firestoreReducer.ordered.products);
  const categories = useSelector((state) => state.firestoreReducer.ordered.categories);
  return (
    <Row gutter={16}>
      <AddDiscount products={products} categories={categories} />
      <DiscountList products={products} categories={categories} />
    </Row>
  );
};

export default Promotions;
