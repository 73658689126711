/**
 * Save files to storage, then add file urls to corresponding product
 * @param {*} firebase Firebase object containing storage functions
 * @param {*} imagesPath Storage path where images have to be stored
 * @param {*} fileArray Array containing the id's of the files to delete
 * @param {*} imageUrlArray Array of urls that get linked to product
 * @param {*} documentId ID of the product that needs to be updated
 * @returns
 */
import { FIRESTORE_PRODUCTS_TABLE } from "../../constants/FirestoreConstant";
import firebase from "../../configs/FirebaseConfig";

export const saveFilesToStorage = (firebase, imagesPath, fileArray, imageUrlArray, documentId) => {
  return new Promise((resolve, reject) => {
    firebase
      .uploadFiles(imagesPath, fileArray, imagesPath, {
        name: function (file) {
          return file.uid;
        },
        metadataFactory: (uploadRes, firebase, metadata, downloadUrl) => {
          // Push the downloadUrl to the existing files array
          imageUrlArray.push(downloadUrl);
          return { image: imageUrlArray };
        },
        // Add the updated files array to our product image array
        documentId: documentId,
      })
      .then(() => {
        resolve();
      })
      .catch((error) => reject(error));
  });
};

/**
 * Delete files from storage, then remove file urls from corresponding product
 * @param {*} firebase Firebase object containing storage functions
 * @param {*} firestore Firestore object containing firestore functions
 * @param {*} fileArray Array containing the id's of the files to delete
 * @param {*} productId ID of the product that needs to be updated
 * @param {*} urlArray Array containing urls that have to be removed from product
 */
export const deleteFilesFromStorage = (firebase, firestore, fileArray, productId, urlArray) => {
  fileArray.forEach((item) => {
    firebase.deleteFile(item).catch((error) => console.error(error));
  });
  // Remove url from image array
  firestore
    .collection(FIRESTORE_PRODUCTS_TABLE)
    .doc(productId)
    .update({
      image: firebase.firestore.FieldValue.arrayRemove(...urlArray),
    })
    .catch((error) => console.error(error));
};

/**
 * USED WHEN DELETING ENTIRE PRODUCTS
 * Delete all associated files from storage when deleting a product
 * @param {*} firebase Firebase object containing storage functions
 * @param {*} images Array of images (storage urls) that have to be deleted from storage
 */
export const deleteAssociatedFilesFromStorage = (firebase, images) => {
  if (!!images) {
    images.forEach((item) => {
      let fileRef = firebase.storage().refFromURL(item);
      fileRef.delete().catch((error) => console.log(error));
    });
  }
};

export const uploadImageToStorage = (item, path) => {
  return new Promise((resolve, reject) => {
    const storage = firebase.storage();
    const imageRef = storage.ref(path + "/" + item.name);
    imageRef
      .put(item.file)
      .then((snapshot) => {
        snapshot.ref.getDownloadURL().then((url) => {
          resolve({ url });
        });
      })
      .catch((error) => {
        reject(item, error.message);
      });
  });
};
