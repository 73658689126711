export const FREE_DELIVERY_FROM_PRICE = 49.99;
export const SEND_COST = 2.95;
export const CURRENCY = "€ ";
export const DELIVERY_OPTIONS = {
  PICKUP: "pickup",
  SEND: "send",
};

export const PAYMENT_METHODS = {
  CREDITCARD: "creditcard",
  IDEAL: "ideal",
  BANCONTACT: "bancontact",
};

export const ADDRESS_TYPES = {
  DELIVERY: "DELIVERY",
  INVOICE: "INVOICE",
};

export const DELIVERY_DURATION = 2;
export const DELIVERY_DATE_WARNING_DAYS = 28;
