import React from "react";

export default function Footer() {
  return (
    <footer className="footer">
      <span>
        Powered by <span className="font-weight-semibold">Digital KISS</span>
      </span>
    </footer>
  );
}
