import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Checkbox,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { VARIATION_WEIGHT } from "../../../../constants/VariationConstant";
import i18n from "../../../../translations/i18n";
import _ from "lodash";

const VariationField = (props) => {
  const parentPrice = props.form.getFieldsValue().price;
  const variations = props.variations;
  const [hasParentPrice, setHasParentPrice] = useState([]);
  const { t } = useTranslation();
  const variation = VARIATION_WEIGHT;

  useEffect(() => {
    if (!!variations) {
      let sameAsParent = [];
      variations.forEach((item, index) => {
        item.price === parentPrice
          ? (sameAsParent[index] = true)
          : (sameAsParent[index] = false);
      });
      setHasParentPrice(sameAsParent);
    }
  }, [variations]);

  const getVariationText = (variation) => {
    if (variation === VARIATION_WEIGHT) return t("variations.weight");
  };

  const onChangeCheckbox = (value, index) => {
    let arr = [...hasParentPrice];
    arr[index] = value;
    setHasParentPrice(arr);
    // If set to true, set input back to Parent Price
    if (value === true) {
      let oldVariations = props.form.getFieldValue("variations");
      // Create the new variation (the one that is being checked/unchecked)
      let updatedVariation = _.set(
        { ...oldVariations[index] },
        "price",
        parentPrice
      );
      // Clone oldVariations into new variable, because oldVariations is immutable
      let newVariations = [...oldVariations];
      // Append updatedVariation object to the new array and set to form fields
      newVariations[index] = updatedVariation;
      props.form.setFieldsValue({
        variations: newVariations,
      });
    }
  };

  return (
    <Card title={t("variations.variation")}>
      <Form.List name="variations">
        {(fields, { add, remove }) => {
          return (
            <div className="m-3">
              {fields.map((field, index) => (
                <Row key={field.key} gutter={10}>
                  <Col sm={24} md={6}>
                    <Form.Item
                      {...field}
                      label={t("variations.type")}
                      name={[field.name, "type"]}
                      className="w-100"
                      initialValue={variation}
                      rules={[
                        {
                          required: true,
                          message: i18n.t("variations.choose_variation"),
                        },
                      ]}
                    >
                      <Select value={variation} disabled>
                        <Select.Option value={variation}>
                          {getVariationText(variation)}
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col sm={24} md={6}>
                    <Form.Item
                      {...field}
                      label={i18n.t("variations.weight")}
                      name={[field.name, "weight"]}
                      fieldKey={[field.fieldKey, "weight"]}
                      className="w-100"
                      rules={[
                        {
                          required: true,
                          message: i18n.t("form.enter_weight"),
                        },
                      ]}
                    >
                      <Input type="number" className="w-100" suffix={"gram"} />
                    </Form.Item>
                  </Col>
                  <Col sm={20} md={6}>
                    <Form.Item
                      {...field}
                      label={i18n.t("price")}
                      name={[field.name, "price"]}
                      fieldKey={[field.fieldKey, "price"]}
                      className="w-100"
                      initialValue={parentPrice}
                      rules={[
                        {
                          required: true,
                          message: i18n.t("form.enter_price"),
                        },
                      ]}
                    >
                      <InputNumber
                        key={index}
                        className="w-100"
                        formatter={(value) =>
                          `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        disabled={hasParentPrice[index]}
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={4} md={5} style={{ alignSelf: "center" }}>
                    <Checkbox
                      checked={hasParentPrice[index]}
                      onChange={(e) =>
                        onChangeCheckbox(e.target.checked, index)
                      }
                    >
                      {t("form.price_checkbox")}
                    </Checkbox>
                  </Col>
                  <Col sm={24} md={1} style={{ alignSelf: "center" }}>
                    <MinusCircleOutlined
                      onClick={() => {
                        remove(field.name);
                      }}
                    />
                  </Col>
                  <Col span={24}>
                    <hr className="mt-2" />
                  </Col>
                </Row>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => {
                    add();
                  }}
                  className="w-100"
                >
                  <PlusOutlined /> {t("form.add_field")}
                </Button>
              </Form.Item>
            </div>
          );
        }}
      </Form.List>
    </Card>
  );
};

export default VariationField;
