import React from "react";
import { Button, Card, Col, Form, Input, Row, Select, Switch } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const VariantField = ({ products, productId }) => {
  const { t } = useTranslation();

  return (
    <>
      <Card title={t("product_head_variant")}>
        <Row gutter={16}>
          <Col sm={24} md={12}>
            <Form.Item
              name={"variantName"}
              label={t("product_variant_name")}
              rules={[
                {
                  required: true,
                  message: t("form.choose_product_variant_name"),
                },
              ]}
            >
              <Input placeholder={t("product_variant_name")} />
            </Form.Item>
          </Col>
          <Col sm={24} md={12}>
            <Form.Item name={["hideVariant"]} label={t("product_variant_display")} valuePropName="checked">
              <Switch checkedChildren={t("yes")} unCheckedChildren={t("no")} />
            </Form.Item>
          </Col>
        </Row>
      </Card>
      <Card title={t("related_variant")}>
        <Form.List name="productVariants">
          {(fields, { add, remove }) => {
            return (
              <div className="m-3">
                {fields.map((field, index) => (
                  <Row key={field.key} gutter={15}>
                    <Col sm={23}>
                      <Form.Item
                        {...field}
                        label={t("product")}
                        className="w-100"
                        name={[field.name, "productId"]}
                        fieldKey={[field.fieldKey, "productId"]}
                        rules={[
                          {
                            required: true,
                            message: t("form.choose_product"),
                          },
                        ]}
                      >
                        <Select showSearch key={index} optionFilterProp="children" filterOption={true}>
                          {products
                            .filter((product) => product.id !== productId)
                            .map((product) => (
                              <Option key={product.id} value={product.id}>
                                {products.find((item) => item.name === product.name).name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col style={{ alignSelf: "center" }}>
                      <MinusCircleOutlined
                        onClick={() => {
                          remove(field.name);
                        }}
                      />
                    </Col>
                    <Col span={24}>
                      <hr className="mt-2" />
                    </Col>
                  </Row>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => {
                      add();
                    }}
                    className="w-100"
                  >
                    <PlusOutlined /> {t("form.add_field")}
                  </Button>
                </Form.Item>
              </div>
            );
          }}
        </Form.List>
      </Card>
    </>
  );
};

export default VariantField;
