import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import Orders from "./orders";
import Products from "./products";
import Reports from "./reports";
import Customers from "./customers";
import Discounts from "./discounts";
import Recipes from "./recipes";
import Admin from "./admin";
import Blog from "./blog";

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Routes>
        <Route path={`/reports`} element={<Reports />} />
        <Route path={`/products/*`} element={<Products />} />
        <Route path={`/orders`} element={<Orders />} />
        <Route path={`/customers/*`} element={<Customers />} />
        <Route path={`/discounts/*`} element={<Discounts />} />
        <Route path={`/recipes`} element={<Recipes />} />
        <Route path={`/blog`} element={<Blog />} />
        <Route path={`/admin/*`} element={<Admin />} />
        <Route path={``} element={<Navigate to={`${APP_PREFIX_PATH}/orders`} replace />} />
      </Routes>
    </Suspense>
  );
};

export default React.memo(AppViews);
