import React, { useEffect, useState } from "react";
import { Card, Input, Table, Tooltip } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Flex from "components/shared-components/Flex";
import utils from "utils";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import dayjs from "moment";
import { DATE_FORMAT_DD_MM_YYYY_HH_mm } from "../../../../constants/DateConstant";

const CustomerFeedback = () => {
  const userFeedback = useSelector((state) => state.firestoreReducer.ordered.userFeedback);
  const [customerList, setCustomerList] = useState(null);
  const [filteredCustomerList, setFilteredCustomerList] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (!!userFeedback) {
      setCustomerList(userFeedback);
      setFilteredCustomerList(userFeedback);
    }
  }, [userFeedback]);

  const tableColumns = [
    {
      title: t("date"),
      dataIndex: "createdAt",
      render: (data) => <span>{dayjs.unix(data).format(DATE_FORMAT_DD_MM_YYYY_HH_mm)}</span>,
      sorter: (a, b) => utils.antdTableSorter(a, b, "createdAt"),
      // defaultSortOrder: "descend",
    },
    {
      title: t("email"),
      dataIndex: "email",
      render: (data) => <span>{data}</span>,
      sorter: (a, b) => utils.antdTableSorter(a, b, "email"),
    },
    {
      title: t("customer.firstName"),
      dataIndex: "firstName",
    },
    {
      title: t("customer.lastName"),
      dataIndex: "lastName",
    },
    {
      title: t("customer.feedback"),
      dataIndex: "message",
      ellipsis: { showTitle: false },
      render: (text) => (
        <Tooltip placement={"bottom"} title={text}>
          {text}
        </Tooltip>
      ),
    },
  ];

  const onSearch = (e) => {
    const value = e.currentTarget.value;
    const data = utils.wildCardSearchOnCustomer(customerList, value);
    setFilteredCustomerList(data);
  };

  return (
    <Card>
      <Flex alignItems="center" justifyContent="between" mobileFlex={false}>
        <Flex className="mb-1" mobileFlex={false}>
          <div className="mr-md-3 mb-3">
            <Input placeholder={t("search")} prefix={<SearchOutlined />} onChange={(e) => onSearch(e)} />
          </div>
        </Flex>
      </Flex>
      <div className="table-responsive">
        <Table columns={tableColumns} dataSource={filteredCustomerList} rowKey="email" scroll={{ x: 1300 }} />
      </div>
    </Card>
  );
};

export default CustomerFeedback;
