import firebase from "../../configs/FirebaseConfig";

export const getFirebaseIDToken = () => {
  return new Promise((resolve, reject) => {
    if (!!firebase.auth().currentUser) {
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then((idToken) => resolve(idToken))
        .catch((error) => reject(error));
    } else reject("No user logged in");
  });
};
