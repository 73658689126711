import { SET_ORDERS_MAX_DAYS_AGO } from "../constants/Queries";
import { FIRESTORE_DEFAULT_ORDERS_MAX_DAYS } from "../../constants/FirestoreConstant";

const initState = {
  ordersMaxDaysAgo: FIRESTORE_DEFAULT_ORDERS_MAX_DAYS,
};

const queries = (state = initState, action) => {
  switch (action.type) {
    case SET_ORDERS_MAX_DAYS_AGO:
      return {
        ...state,
        ordersMaxDaysAgo: !!action.days ? action.days : null,
      };
    default:
      return state;
  }
};

export default queries;
