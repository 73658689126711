const { notification } = require("antd");

/**
 * Show notification that does not go away automatically
 * @param {*} title
 * @param {*} description
 */
export const showInfiniteNotification = (title, description) => {
  notification.open({
    message: title,
    description: description,
    duration: 0,
  });
};
